import { AxiosRequestConfig } from "axios";
import {  useEffect, useState } from "react";
import useUpdateEffect from "../useUpdateEffect";
import useGet from "./useGet";
import useCache from "../useCache";

const TEMP_CAP_AT_10000 = true;


  const useGetAllPaginatedItems = <ItemType>(
    requestFunction: (pageSize: number, page: number, values?:any) => AxiosRequestConfig,
    pageSize: number,
    idField = "ProductId",
    requestValues: any = null,
    shouldRequest: boolean = true
  ):ItemType[] => {
    const [page, setPage] = useState(1);
    const originalRequestUrl = requestFunction(pageSize, 0,requestValues).url;
    const [items, setItems] = useState<ItemType[] | undefined>(null);
    const [newCache, setNewCache] = useCache(originalRequestUrl,items)
    const [request, setRequest] = useState<AxiosRequestConfig<any>>(shouldRequest ? requestFunction(pageSize, 1, requestValues) : null);
    const [results, abort] = useGet(request, page);

  
    useEffect(() => {
      updateItems(results?.Items as ItemType[]);
    }, [results]);
  
    const updateItems = (newItems: ItemType[]) => {
      if (newItems?.length > 0) {
        let updatedItems = newCache ? [...newCache] : [];
    
        newItems.forEach(newItem => {
          const existingItemIndex = updatedItems.findIndex(
            (existingItem) => existingItem[idField] === newItem[idField]
          );
    
          if (existingItemIndex !== -1) {
            // Update existing item
            updatedItems[existingItemIndex] = newItem;
          } else {
            // Add new item
            updatedItems.push(newItem);
          }
        });
    
        setItems(updatedItems);
      }
    };
  
    useUpdateEffect(() => {
      if (results?.Remaining > 0 && !(TEMP_CAP_AT_10000 && newCache?.length>9999)) {
        setPage(page + 1);
        setRequest(requestFunction(pageSize, page + 1));
      }
    }, [items]);
  
    useUpdateEffect(() => {
      if (shouldRequest) {
        setPage(1);
        setRequest(requestFunction(pageSize, 1, requestValues));
      }
    }, [shouldRequest]);
    return newCache;
  };
  

export default useGetAllPaginatedItems;