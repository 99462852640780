import { AxiosRequestConfig } from "axios";
import {  Dispatch, SetStateAction, useEffect, useState } from "react";
import useRequest from "./useRequest";
import useCache from "../useCache";
import { shouldRequest } from "src/utils/callApi";


export const useResponse = <ResponseType>(
  requestFunction: (...args: any[]) => AxiosRequestConfig<any>,
  values?: Record<string, any>,
  requestNothingOnNull: boolean = false,
  dependency?: any[] | any
): [result: ResponseType | null, setNewValue:Dispatch<SetStateAction<ResponseType>>,abort: () => void] => {
  const requestFunctionValues = Object.values(values ?? {});
  
  const originalRequest = requestFunction([...requestFunctionValues]);
  console.log("Original Request", originalRequest)
  const [result, setRequest, abort] = useRequest();
  const response = result?.Items as ResponseType ?? (result as ResponseType);
  const [cachedResponse, setNewValue] = useCache<ResponseType | null>( originalRequest?.url,response);
  useEffect(() => {
    if (shouldRequest(requestNothingOnNull, requestFunctionValues)) {
      // console.log("Rquesting ", originalRequest.url)
      setRequest(requestFunction(...requestFunctionValues));
    }
  }, [...(Array.isArray(dependency) ? dependency : [dependency])]);

  return [cachedResponse, setNewValue,abort];
};

export const usePaginatedResponse = <ResponseType>(
  requestFunction: (...args: any[]) => AxiosRequestConfig<any>,
  perPage: number = 10,
  initialPage: number = 1,
  values?: Record<string, any>,
  dependency?: any[] | any,
): [result: ResponseType[] | null, loadMore: () => void, abort: () => void] => {
  const [page, setPage] = useState(initialPage);
  const [allResults, setAllResults] = useState<ResponseType[]>([]);
  
  const [result, setNewValue, abort] = useResponse(requestFunction, {
    ...values,
    page,
    perPage
  }, false, [dependency, page]);

  useEffect(() => {
    if (result) {
      setAllResults(prev => [...prev, ...result as any[] ]);
    }
  }, [result]);

  const loadMore = () => {
    setPage(prev => prev + 1);
  };

  return [allResults, loadMore, abort];
};
