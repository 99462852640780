import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

export const ToggleHeaderFilter = ({ props, tableSettings }) => {
    const { headerFilter, setHeaderFilter } = tableSettings;

    const toggleHeaderFilter = () => {
        setHeaderFilter(!headerFilter);
        tableSettings.apiRef.current.hideColumnMenu();
    };

    return (
        <MenuItem onClick={toggleHeaderFilter}>
            <ListItemIcon>
                {headerFilter ?  <FilterListOffIcon fontSize="small" />:<FilterListIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>{headerFilter ? "Disable Header Filter" : "Enable Header Filter"}</ListItemText>
        </MenuItem>
    );
};
