import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";



const Logout =() => {
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const handleLogout = async (): Promise<void> => {
      try {
        await logout();
        navigate('/');
      } catch (err) {
        console.error(err);
      }
    };
    handleLogout();
    return (
        <>
          
        </>
    );
}

export default Logout;
