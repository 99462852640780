import { useContext } from 'react';
import Scrollbar from 'src/components/atoms/scrollbar/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';


import { Box, Button, Drawer, IconButton, styled, useTheme } from '@mui/material';

import SidebarMenu from './MenuWrapper';
import Logo from 'src/components/atoms/logo/logoSign/Logo';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import Close from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 3)};
`
);

const CloseButton = styled(IconButton)(
  ({ theme }) => `
        position: absolute;
        top: ${theme.spacing(1)};
        right: ${theme.spacing(1)};
        color: ${theme.sidebar.textColor};
`
);

const MenuButton = styled(IconButton)({
  position: 'fixed',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  zIndex: 1000, // Ensure it's above other elements
});

function Sidebar() {
  const { sidebarToggle, toggleSidebar, desktopSidebarToggle, toggleDesktopSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            md: desktopSidebarToggle ? 'inline-block' : 'none',
          },
          position: 'fixed',
          left: 0,
          top: 0
        }}
        className={'sidebar-layout-content'}
      >
        <Scrollbar>
          <TopSection>
            <Box
              sx={{
                width: 52,
                mt: 2,
                mb: 3
              }}
            >
              <Logo />
              <CloseButton size='small' onClick={()=>toggleDesktopSidebar()} ><Close  /></CloseButton>
            </Box>
            
          </TopSection>
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      {!desktopSidebarToggle && <MenuButton   sx={{
          display: {
            xs: 'none',
            md: 'inline-block',
          },
        
        }} onClick={toggleDesktopSidebar}>
      <ArrowForwardIosIcon/>
      </MenuButton>}
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper  className={'sidebar-layout-content'}>
          <Scrollbar>
            <TopSection>
              <Box
                sx={{
                  width: 52,
                  ml: 1,
                  mt: 1,
                  mb: 3
                }}
              >
                <Logo />
              </Box>
        
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
