import { Link as RouterLink } from 'react-router-dom';
import { Button, CircularProgress, IconButton, IconButtonPropsColorOverrides, IconButtonPropsSizeOverrides, Tooltip } from '@mui/material';
import { ButtonTypeMap } from "@mui/material"
import { OverridableStringUnion } from '@mui/types';
import { ReactNode } from "react";
import { t } from 'i18next';

export interface ActionButtonProps {
  title?: string;
  actionUrl?: string;
  isSubmitting?: boolean;
  variant?: ButtonTypeMap["props"]["variant"];
  iconButton?: boolean;
  icon?: ReactNode;
  actionType?: string;
  target?: string;
  toolTipTitle?:string;
  className?:string;
  disabled?:boolean;
  values?: {};
  color?: OverridableStringUnion<
    'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    IconButtonPropsColorOverrides
  >
  onClick?: Function;
  size?:  OverridableStringUnion<"small" | "medium" | "large", IconButtonPropsSizeOverrides>
}

export const CustomButton = ({
  title,
  actionUrl = null,
  isSubmitting = false,
  variant = 'contained',
  iconButton,
  toolTipTitle = "",
  icon,
  disabled = false,
  actionType,
  target = "_self",
  values,
  color = "primary",
  onClick: actionFunction = ()=> (console.log("Action Function not Implemented")),
  size = "medium",
  className = "custom-button-"+title?.replace(/ /g, "-").toLowerCase()
}: ActionButtonProps) => {
  if (iconButton) {
    if (actionUrl) {
      return (
        <Tooltip title={toolTipTitle} arrow>
        <IconButton
          component={RouterLink}
          disabled={disabled}
          to={actionUrl ?? ''}
          onClick={(e) => { actionFunction(e) }}
          color={color}
          target={target}
          size={size}
          className={className}
        >
          {icon}
        </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={toolTipTitle} arrow>
        <IconButton
          onClick={(e) => { actionFunction(e) }}
          color={color}
          disabled={disabled}
          size={size}
          className={className}
        >
          {icon}
        </IconButton>
        </Tooltip>
      );
    }
  } else {
    if (actionUrl) {
      return (
        <Tooltip title={toolTipTitle} arrow>
        <Button
          component={RouterLink}
          to={actionUrl ?? ""}
          target={target}
          className={className}
          variant={variant}
          color={color as "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning"}
          fullWidth
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : (icon)}
          disabled={disabled ?? isSubmitting}
          size={size}
          sx={{
            mt: { xs: 1, sm: 0 }
          }}
        >
          {title}
        </Button>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={toolTipTitle} arrow>
        <Button
          onClick={(e) => { actionFunction(e) }}
          variant={variant}
          fullWidth
          className={className}
          size={size}
          color={color as "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning"}
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : (icon)}
          disabled={disabled ?? isSubmitting}
          sx={{
            mt: { xs: 1, sm: 0 }, color:color
          }}
        >
          {title}
        </Button>
        </Tooltip>
      );
    }


  }

}

export default CustomButton;