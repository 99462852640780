import {  GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useGetAllBackorders } from '../../services/bold/purchasing';




export const useBackordersTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetAllBackorders();
  const statusOptions = tableHelper.getSelectOptions(rows, "Status");
  const idPath = ["ArticleNumber"];
  const columns = React.useMemo(
    () => [
      { field: 'ArticleNumber',headerName: 'ArticleNumber',width:70,},
      { field: 'Article', headerName: 'Article',width:120, valueGetter:  tableHelper.getFieldKeyOrValue, flex:1 },
      { field: 'Status', headerName: 'Status',renderCell: tableHelper.renderStatusCell, type: 'singleSelect', valueOptions: statusOptions  },
      { field: 'TotalQty', headerName: 'TotalQty', type:"number"  },
      { field: 'Warehouse', headerName: 'Warehouse', flex:1  },
      { field: 'ProductType', headerName: 'ProductType',  },
      { field: 'ProductTypeId', headerName: 'ProductTypeId',  },
      { field: 'Manufacturer', headerName: 'Manufacturer',  },
      { field: 'ManufacturerProdId', headerName: 'ManufacturerProdId',  },
      { field: 'LastCreatedDate', headerName: 'LastCreatedDate', type: 'dateTime', valueGetter:  tableHelper.getDateColumn, },
    ],
    [statusOptions],
  );
  const tableName = "BackordersTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};




export const useBackordersRowsTable = (rows = []): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const idPath = ["OrderNrCol"]
  const columns = React.useMemo(
    () => [
      { field: 'OrderNrCol', headerName: 'OrderNrCol', },
      { field: 'SourceDestinationCol', headerName: 'SourceDestinationCol' },
      { field: 'SourceDestinationNameCol', headerName: 'SourceDestinationNameCol', flex:1 },
      { field: 'UserCol', headerName: 'UserCol', flex:1 },
      { field: 'Quantity', headerName: 'Quantity', type:"number" },
      { field: 'SourceNameCol', headerName: 'SourceNameCol' },
      { field: 'SupplierId', headerName: 'SupplierId' },
      
      { field: 'ETACol', headerName: 'ETACol' },
      { field: 'DateCol', headerName: 'DateCol', type: 'dateTime', valueGetter: tableHelper.getDateColumn, },
    ],
    [],
  );
  
  const tableName = "BackordersRowsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  tableSettings.hasToolbar=false;
  tableSettings.autoHeight=true;
  tableSettings.loading=false;
  tableSettings.hideFooter=true;
  
  return [ columns, tableSettings];
};