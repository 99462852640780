import PageHeader from 'src/components/organisms/PageHeader';

import Footer from 'src/components/molecules/footer/Footer';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { useReturnOrdersTable } from 'src/hooks/table/configs/useReturnOrdersTable';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { useWarehouseValuesSummaryTable } from 'src/hooks/table/configs/useWarehouseReportsTable';


function WarehouseValueSummaryReport() {
    const [columns, tableSettings] = useWarehouseValuesSummaryTable();

  
    return (
        <>
            <PageHeader  {...{
                title: "Warehouse Value Summary Report",
                subTitle: "A report that shows the value in money of the items in all warehouses",
                components: [
                    // { component: <CustomButton title="New Return Order" icon={<AddTwoToneIcon />} actionUrl="/management/commerce/products/create" /> },
                ],
            }} />

            <ContentCardsContainer >
                <ContentCard {...{ cardId: "Warehouse Values Report", cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings} />
                </ContentCard>

             

            </ContentCardsContainer>

            <Footer />
        </>
    );
}

export default WarehouseValueSummaryReport;
