import { FC, ReactNode, useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from "./SidebarWrapper";
import { SidebarContext } from 'src/contexts/SidebarContext';

interface AccentSidebarLayoutProps {
  children?: ReactNode;
}

const AccentSidebarLayout: FC<AccentSidebarLayoutProps> = () => {
  const theme = useTheme();
  const {  desktopSidebarToggle, toggleDesktopSidebar } = useContext(SidebarContext);

  return (
    <>
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          flex: 1,
          
          display: 'flex',
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('md')]: {
            pl: `${desktopSidebarToggle ? theme.sidebar.width : "10px"}`
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AccentSidebarLayout;
