import React from 'react';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { options } from 'numeral';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import SaveDiscardButtons from 'src/components/atoms/buttons/SaveDiscardButton';
import { CustomField } from 'src/components/molecules/formik/CustomField';

export const generateTablePageButtons = (tableSettings, submit = null) => {
  const components = [
    {
      component: (
        <CustomField
          stateProps={{
            state: tableSettings.quickFilterValues,
            setState: tableSettings.setQuickFilterValues,
          }}
          options={{
            name: "QuickFilter",
            label: "Quick search",
            height: "40px",
            topMargin: "0px",
            className: "quick-search"
          }}
        />
      ),
    },
    ...(submit ? [{
      component: <SaveDiscardButtons tableSettings={tableSettings} submit={submit} />,
    }] : []),
    {
      isInGroupedMenu: true,
      component: (
        <CustomButton
          variant='text'
          title="Save as Excel"
          
          onClick={() => tableSettings?.apiRef.current.exportDataAsExcel()}
        />
      ),
    },
    {
      isInGroupedMenu: true,
      component: (
        <CustomButton
          variant='text'
          title="Save as Csv"
          onClick={() => tableSettings?.apiRef.current.exportDataAsCsv()}
        />
      ),
    },
    {
      isInGroupedMenu: true,
      component: (
        <CustomButton
          variant='text'
          title="Print"
          onClick={() => tableSettings?.apiRef.current.exportDataAsPrint()}
        />
      ),
    },
    {
      isInGroupedMenu: true,
      component: (
        <CustomButton
          variant='text'
          title={`${tableSettings?.headerFilter ? "Hide" : "Show"} Filters`}
          onClick={() => tableSettings?.setHeaderFilter(!tableSettings?.headerFilter)}
        />
      ),
    },
  ];

  return components;
};
