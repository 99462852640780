import {  GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useGetAllTransferOrders } from '../../services/bold/warehouse';
import useUpdateEffect from '../../useUpdateEffect';



export const useTransferOrdersTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetAllTransferOrders();
  const idPath = ["OrderId"];
  const columns = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/transfers/edit?id=", "OrderId", "Edit Order"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'OrderId', headerName: 'OrderId', },
      { field: 'CreatedBy', headerName: 'CreatedBy', },
      { field: 'Status_Value', headerName: 'Status', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'FromWarehouse_Value', headerName: 'FromWarehouse', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'ToWarehouse_Value', headerName: 'ToWarehouse', valueGetter: tableHelper.getFieldKeyOrValue, },
      { field: 'ETA', headerName: 'ETA', type: 'dateTime', valueGetter:  tableHelper.getDateColumn, },
      { field: 'CreateDate', headerName: 'CreateDate', type: 'dateTime', valueGetter:  tableHelper.getDateColumn, },
      { field: 'ModifyDate', headerName: 'ModifyDate', type: 'dateTime', valueGetter:  tableHelper.getDateColumn, },
      { field: 'DeliveryMethodId', headerName: 'DeliveryMethodId', type:"number"},
      { field: 'DispatchOrderId', headerName: 'DispatchOrderId', type:"number"},
      { field: 'ReceivingAdviceId', headerName: 'ReceivingAdviceId', type:"number"},
    ],
    [],
  );
  const tableName = "TransferOrderTable";
  let tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};


export const useTransferOrdersRowsTable = (rows = []): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const idPath = ["RowNr"];
  const columns = React.useMemo(
    () => [
      
      { field: 'RowNr', headerName: 'RowNr' },
      { field: 'OrderRowId', headerName: 'OrderRowId', width: 50 },
      { field: 'ProductId', headerName: 'ProductId' },
      { field: 'Product', headerName: 'Product' },
      { field: 'Comment', headerName: 'Comment' },
      { field: 'Qty', headerName: 'Qty', type:"number" },
      { field: 'QtyTransferred', headerName: 'QtyTransferred', type:"number" },
      { field: 'AvailableQtyAtTime', headerName: 'AvailableQtyAtTime', type:"number" },
    ],
    [],
  );
  
  const tableName = "TransferOrdersRowsTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  tableSettings.hasToolbar=false;
  tableSettings.autoHeight=true;
  tableSettings.loading=false;
  tableSettings.hideFooter=true;
  
  return [ columns, tableSettings];
};



export const useTransferOrderRowsTable = (initialValues): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const [rows, setRows] = useState([])
  const idPath = ["OrderRowId"];
  useUpdateEffect(() => {
    if (rows?.length < 1) {
      setRows(initialValues?.TransferOrderRows ?? [])
    }
  }, [initialValues?.TransferOrderRows])
  const columns = React.useMemo(
    () => [
      { field: 'RowNr', headerName: '#', type:"number" , width:40},
      { field: 'OrderRowId', headerName: 'OrderRowId', type:"number"},
      { field: 'Product', headerName: 'Product Name' },
      { field: 'ProductId', headerName: 'ProductId' },
      { field: 'Qty', headerName: 'Qty', type:"number" },
      { field: 'QtyTransferred', headerName: 'QtyTransferred', type:"number" },
      { field: 'AvailableQtyAtTime', headerName: 'AvailableQtyAtTime', type:"number" },
      { field: 'Comment', headerName: 'Comment' },
    
    ],
    [initialValues],
  );
  const tableName = "TransferOrderRowsTable";
  const tableSettings = useTableSettings({columns, idPath, rows, tableName});
  
  tableSettings.autoHeight = true;
  tableSettings.hasToolbar=false;
  return [ columns, tableSettings];
};
