import * as Yup from 'yup';
import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import ContentCardsContainer, { CardTab, CardType, ContentCard, HeaderTabs } from 'src/components/organisms/ContentCard';
import { Formik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import { FormContainerMolecule } from 'src/components/molecules/formik/FormContainerMolecule';
import { FormField } from 'src/components/molecules/formik/FormField';
import CustomButton from 'src/components/atoms/buttons/CustomButton';
import useSubmit from 'src/hooks/forms/useSubmit';
import {buildSupplierRequest } from 
'src/utils/submit/submitRequests';
import FormHelper from 'src/utils/formHelper';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import { useState } from 'react';
import { useTransferOrderOptions } from 'src/hooks/forms/useOptions';
import MenuItem from '@mui/material/MenuItem';
import { useTransferOrderRowsTable } from 'src/hooks/table/configs/useTransferOrdersTable';
import { useTransferOrderForm } from 'src/hooks/forms/useForms';

const scheme = Yup.object().shape({
});

const defaultInitialValues = {
};

const EditTransferOrder = () => {
    const [editId, PageName, currentSubmitFunction, , isEditPage] = FormHelper.getEditPageConfig("Transfer Order", buildSupplierRequest,buildSupplierRequest);
    const initialValues = useTransferOrderForm(editId, defaultInitialValues)
    const [orderColumns, orderTableSettings] = useTransferOrderRowsTable(initialValues)
    const [deliverMethodOptions] = useTransferOrderOptions();
    const [result, submit] = useSubmit(currentSubmitFunction);

    const tabs: CardTab[] = [
        { value: 'Order Rows', label: 'Order Rows' },
    ]
    const [currentTab, setCurrentTab] = useState<string>('Order Rows');
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={scheme}
                onSubmit={submit}
                enableReinitialize
            >
                {(props): JSX.Element => (
                    <div>
                        <PageHeader  {...{
                            title: PageName,
                            subTitle: "Create and edit Transfer Order",
                            components: [
                                { component: <CustomButton isSubmitting={props.isSubmitting} onClick={props.handleSubmit} title="Save" icon={<SaveIcon />} /> },
                            ],
                            alertData: { dataArray: (result ?? false) ? [result] : null }
                        }} />
                        <ContentCardsContainer >
                            <ContentCard {...{ cardId: "Base Info", md: 6 }}>
                                <FormContainerMolecule formikProps={props}>
                                    <FormField fieldProps={{ name: "OrderId", disabled: true, label: "OrderId", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ReceivingAdviceId", label: "ReceivingAdviceId", md: 5.8 }} formikProps={props}></FormField>

                                    <FormField fieldProps={{ name: "Status_Value", label: "Status", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "CreateDate", label: "Created By", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "CreatedBy", label: "CreatedBy", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ModifyDate", label: "ModifyDate", md: 5.8 }} formikProps={props}></FormField>
                                
                                </FormContainerMolecule>
                            </ContentCard>

                            <ContentCard {...{ cardId: "Delivery", md: 6 }}>
                                <FormContainerMolecule formikProps={props}>
                                
                                    <FormField fieldProps={{ name: "FromWarehouse_Value", label: "FromWarehouse", md: 8.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "FromWarehouse", label: "FromWarehouse Id", md: 2.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ToWarehouse_Value", label: "FromWarehouse", md: 8.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ToWarehouse", label: "FromWarehouse Id", md: 2.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ExternalShipmentId", label: "ExternalShipment Id", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "ETA", label: "ETA", md: 5.8 }} formikProps={props}></FormField>
                                    <FormField fieldProps={{ name: "DeliveryMethodId", select: true, label: "DeliveryMethod", md: 12 }} formikProps={props}>
                                        {deliverMethodOptions?.map((option) => (<MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>))}
                                    </FormField>
                                    

                                </FormContainerMolecule>
                            </ContentCard>
                    

                           
                            <ContentCard {...{
                                cardId: "TabContainer",
                                contentPadding: 0,
                                cardTypes: [CardType.tabContainer],
                                cardHeader: <HeaderTabs {...{ tabs, currentTab, setCurrentTab }} ></HeaderTabs>,
                                components: [
                                    //{ isInGroupedMenu: true, component: <ActionButton target='blank' variant='text' title="New Supplier Product" icon={<AddTwoToneIcon />} actionUrl={"/products/supplier-products/create?productId=" + editId} />},
                                ],
                            }}>
                                {currentTab === "Order Rows" &&

                                    <ContentCard {...{ cardId: "Order Rows", cardTypes: [CardType.tab], contentPadding: 0, }}>
                                        <CustomDataGrid  columns={orderColumns} tableSettings={orderTableSettings} />
                                    </ContentCard>
                                }
                                
                            </ContentCard>


                        </ContentCardsContainer>
                    </div>
                )}
            </Formik>


            <Footer />
        </>
    );
}

export default EditTransferOrder;
