import { useEffect, useState } from 'react';
import { Fab, Dialog } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import ChatContent from './ChatContent'; // Adjust the path as necessary
import BottomBarContent from './BottomBarContent'; // Adjust the path as necessary
import { useAiChat } from 'src/hooks/useAi';

function FloatingChat() {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };
    const [responses, generateResponse] = useAiChat()

    const [messages, setMessages] = useState([
        {
            content: "Hi! I am an AI Assistant powered by GPT4. How can i help you?",
            user: false,
        },
    ]);
    const sendMessage = (newMessageInput) => {
        const newMessage = {
            content: newMessageInput,
            user: true
        }
        generateResponse(newMessageInput)
        setMessages(prevMessages => [...prevMessages, newMessage]);
    }
    useEffect(() => {
        if (responses?.length > 0) {
            const newMessage = {
                content: responses[responses.length - 1],
                user: false
            }
            setMessages(prevMessages => [...prevMessages, newMessage]);
        }

    }, [responses])

    return (
        <div style={{ zIndex:20, position: 'fixed', bottom: 16, right: 16 }}>
            <Fab color="primary" aria-label="chat" onClick={handleClickOpen}>
                <ChatIcon />
            </Fab>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                PaperProps={{
                    style: {
                        position: 'fixed',
                        bottom: 0,
                        maxHeight: '90vh',
                        width: '100%',
                        right: 20,
                        overflow: 'hidden',
                    },
                }}
                maxWidth="sm"
            >
                <ChatContent messages={messages} />
                <BottomBarContent sendMessage={sendMessage} />
            </Dialog>
        </div>
    );
}

export default FloatingChat;
