import React, { useState, createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AlertColor } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAlert } from 'src/hooks/pageHeader/useAlert';
import CustomToastContainer from 'src/components/atoms/CustomToastContainer';

interface EventContainer {
  requests: {
    [key: string]: {
      status: 'pending' | 'success' | 'failure',
      error: string | null,
    },
  },
  messages: {
    success: string,
    failure: string,
  },
  settings: { 
    autoClose: boolean,
    details?: string,
  },
}
type UpdateMessageStatusConfig = {
  id?: string;
  requestUrl: string;
  status: 'success' | 'failure';
  message?: string; 
};

interface MessageContextProps {
  createEventContainer: (id: string, requests: string[], messages: { success: string, failure: string }, settings?:{autoClose?:boolean, details?: string}) => void;
  updateMessageStatus:  (config: UpdateMessageStatusConfig) => void;
  message: string | null;
  messageType: AlertColor | null;
}

export const MessageContext = createContext<MessageContextProps>({
  createEventContainer: () => {},
  updateMessageStatus: () => {},
  message: null,
  messageType: null,
});

export const MessageProvider: React.FC = ({ children }) => {
  const [eventContainers, setEventContainers] = useState<{[key: string]: EventContainer}>({});
  const [eventHistory, setEventHistory] = useState<{[key: string]: EventContainer}>({});
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<AlertColor | null>(null);
  const toastStatus = messageType === 'success' ? 'success' : messageType === 'error' ? 'error' : null;
  const showAlert = useAlert();

  const createEventContainer = (id: string, requests: string[], messages: { success: string, failure: string }, settings = { autoClose: true, details: undefined }) => {
    setEventContainers(prev => ({
      ...prev,
      [id]: {
        requests: requests.reduce((acc, cur) => ({
          ...acc,
          [cur]: { status: 'pending', error: null },
        }), {}),
        messages,
        settings, 
      },
    }));
  };
  const updateMessageStatus = ({ id, requestUrl, status, message = '' }) => {
    setEventContainers(prev => {
      let newContainers = { ...prev };
  
      if (!id) {
        // Handle the case where no ID is provided by creating a temporary unique ID
        id = `temp-${Date.now()}`;
        newContainers[id] = {
          requests: {
            [requestUrl]: { status, error: message },
          },
          messages: {
            success: status === 'success' ? message : 'Operation successful',
            failure: status === 'failure' ? message : 'Operation failed',
          },
          settings: { autoClose: true },
        };
  
        // Immediately move to event history
        setEventHistory(prevHistory => ({...prevHistory,}));
        // Show toast message directly
        const toastMessage = status === 'success' ? newContainers[id].messages.success : newContainers[id].messages.failure;
        toast[status](toastMessage, { autoClose: newContainers[id].settings.autoClose ? 5000 : false });
        
        // Remove from event containers as it is temporary and processed
        delete newContainers[id];
        return newContainers;
      }
  
      // Existing logic for handling updates when ID is provided
      if (newContainers[id] && newContainers[id].requests[requestUrl]) {
        newContainers[id].requests[requestUrl] = { status, error: message };
      }
      return newContainers;
    });
  };
  

  useEffect(() => {
    const checkAndUpdateMessage = (id: string) => {
      const container = eventContainers[id];
      if (!container) return;
  
      const allSucceeded = Object.values(container.requests).every(req => req.status === 'success');
      const anyFailed = Object.values(container.requests).some(req => req.status === 'failure');
  
      if (allSucceeded || anyFailed) {
        const newMessage = allSucceeded ? container.messages.success : `${container.messages.failure}. Details: ${Object.values(container.requests).filter(req => req.error).map(req => req.error).join(', ')}`;
        setMessage(newMessage);
        setMessageType(allSucceeded ? 'success' : 'error');
        showAlert(newMessage, allSucceeded ? 'success' : 'error', container.settings);

        setEventHistory(prevHistory => ({ ...prevHistory, [id]: container }));
        setEventContainers(prevContainers => {
          const newContainers = { ...prevContainers };
          delete newContainers[id];
          return newContainers;
        });
      }
    };
    Object.keys(eventContainers).forEach(checkAndUpdateMessage);
  }, [eventContainers]);


  return (
    <>
      <CustomToastContainer />
      <MessageContext.Provider value={{
        createEventContainer,
        updateMessageStatus,
        message,
        messageType,
      }}>
        {children}
      </MessageContext.Provider>
    </>
  );
};


export const useMessageContext = () => useContext(MessageContext);
