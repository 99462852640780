import {
    Grid, MenuItem,
} from '@mui/material';
import { FC } from 'react';
import { FormField } from './FormField';
import * as Yup from 'yup';


export function getInitialValuesForForm(formFieldData) {
    let initalValues = {};
    formFieldData?.forEach(field => {
        initalValues[field.name] = (field.checkbox ? (field.initialValue ?? false) : (field.initialValue ?? ""));
    });
    return initalValues;
}
export function getSchemeForForm(formFieldData) {
    let scheme = Yup.object({});
    formFieldData?.forEach(field => {
        const fieldSchema = Yup.object().shape({
            [field.name]: field.schema ?? null
        });
        scheme = scheme.concat(
            Yup.object().shape({
                [field.name]: field.schema ?? null
            }))
    });
    return scheme;
}

export const FormContainerMolecule: FC<{ formikProps, formFields?, children?}> = ({ formikProps, formFields, children }) => {
    return (

            <Grid container direction="row" justifyContent="space-between" alignContent={'stretch'}>
                    {formFields?.map((field, i) => (
                        <FormField key={i} fieldProps={{ ...field }} formikProps={formikProps}>
                            {field.options?.map((option, i) => (
                                <MenuItem key={i} value={option.value}>{option.label ?? option.value}</MenuItem>
                            ))}
                        </FormField>
                    ))}
                    {children}
            </Grid>
    );
}