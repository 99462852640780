import { AxiosRequestConfig } from "axios";
import { createOrUpdateSupplierProduct, createOrUpdateSupplier, createOrUpdateReturnOrder, createOrUpdatePurchaseOrderDraft, addReturnOrderRow, editManufacturers, createOrUpdateCategories } from "../services/bold/requests";
import { buildSubmitRequest, filterValidRequests } from "./buildSubmitRequest";
import { mapReturnToValues, mapProductFormValuesToSupplierProductCreate, mapProductFormValuesToSupplierProductUpdate, mapFormValuesToSupplier, mapFormValuesToPurchaseOrderDraftPatch, mapFormValuesToPurchaseOrderDraft, mapProductToReturnRow, mapTableDataToManufacturers, mapTableDataToCategories } from "./mappings";

//SUPPLIER PRODUCTS
export const buildSupplierProductRequest = (values): AxiosRequestConfig => {
    return buildSubmitRequest(values, createOrUpdateSupplierProduct, {
        createMappingFunction: mapProductFormValuesToSupplierProductCreate,
        updateMappingFunction: mapProductFormValuesToSupplierProductUpdate,
        idField: 'SupplierAssortmentItemId'
    });;
};

//SUPPLIERS
export const buildSupplierRequest = (values): AxiosRequestConfig => {
    return buildSubmitRequest(values, createOrUpdateSupplier, { createMappingFunction: mapFormValuesToSupplier });
};

//RETURNS
export const buildReturnsRequest = (values): AxiosRequestConfig[] => {
    const formValues = values?.values;
    const newProduct = values?.newProduct;

    return [
        buildSubmitRequest({...formValues, newProduct:newProduct }, addReturnOrderRow, { createMappingFunction: mapProductToReturnRow, idField: 'ReturnOrderId',  updateMethod: "POST" }),
         buildSubmitRequest(formValues, createOrUpdateReturnOrder, { createMappingFunction: mapReturnToValues, idField: 'ReturnOrderId', updateMethod: "PUT" }),
        
    ];
};

//PURCHASE ORDER DRAFTS
export const buildPurchaseOrderDraftRequest = (values): AxiosRequestConfig => {
    return buildSubmitRequest(values, createOrUpdatePurchaseOrderDraft, {
        createMappingFunction: mapFormValuesToPurchaseOrderDraft,
        updateMappingFunction: mapFormValuesToPurchaseOrderDraftPatch,
        idField: 'PurchaseOrderDraftId'
    });
};



//MANUFACTURERS
export const buildManufacturerRequest = (values): AxiosRequestConfig[] => {
    const requests = [ 
        buildSubmitRequest(values, editManufacturers, { createMappingFunction: mapTableDataToManufacturers }),
        buildSubmitRequest(values, editManufacturers, { createMappingFunction: mapTableDataToManufacturers, isDelete: true}),
    ];
    return filterValidRequests(requests);
};

//CATEGORIES
export const buildCategoriesRequest = (values): AxiosRequestConfig => {
    const request = buildSubmitRequest(values, createOrUpdateCategories, { createMappingFunction: mapTableDataToCategories });
    return request;
};