export const buildSubmitRequest = (
    values,
    createOrUpdateFunction,
    options = {}
) => {
    if (!values || typeof values !== 'object' || Object.keys(values).length === 0) {
       // console.error('Invalid or empty values provided:', values);
        // Return a default or error value as appropriate
        return null;
    }

    const {
        createMappingFunction,
        updateMappingFunction = createMappingFunction,
        deleteMappingFunction = createMappingFunction, 
        idField = 'Id',
        updateMethod = "PATCH",
        isDelete = false, // A flag to determine if the operation is a delete
    }:any = options;

    if (!createMappingFunction || typeof createMappingFunction !== 'function') {
        //console.error('Invalid createMappingFunction provided.');
        // Return a default or error value as appropriate
        return null;
    }

    const method = isDelete ? "DELETE" : (values[idField] == null ? "POST" : updateMethod);
    const mapper = isDelete ? deleteMappingFunction : (values[idField] == null ? createMappingFunction : updateMappingFunction);

    if (!mapper || typeof mapper !== 'function') {
      //  console.error('Invalid mapping function.');
        // Return a default or error value as appropriate
        return null;
    }

    const item = isDelete ? mapper(values, isDelete) : mapper(values);

    if (!item || typeof item !== 'object' || Object.keys(item).length === 0) {
       // console.error('Mapping function returned invalid item:', item);
        // Return a default or error value as appropriate
        return null;
    }

    return createOrUpdateFunction(item, method, values[idField]);
};



export const filterValidRequests = (requests) => {
    // Ensure input is always treated as an array
    const normalizedRequests = Array.isArray(requests) ? requests : [requests];
  
    // Filter requests where 'data' is present and its length is greater than 0
    return normalizedRequests.filter(request => {
      if (!request || typeof request !== 'object') {
       // console.error('Invalid request encountered:', request);
        return false;
      }
  
      const data = request.data;
  
      if (!data) {
       // console.error('Request missing data:', request);
        return false;
      }
  
      const isValidData = typeof data === 'string' || Array.isArray(data) ? data.length > 0 : false;
  
      if (!isValidData) {
      //  console.error('Request data is empty or invalid:', request);
      }
  
      return isValidData;
    });
  };
  