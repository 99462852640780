import {
  Grid,
  Box,
  Typography,
  Divider,
  IconButton,
  Card,
  Collapse,
  Tab,
  Tabs,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import { ChangeEvent, Dispatch, FC, SetStateAction, useContext, useRef, useState } from 'react';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import AdditionalActions from '../atoms/buttons/AdditionalActions';
import TooltipField from '../atoms/TooltipField';
import { customComponent } from 'src/interfaces/customComponent';
import { separateMenuComponents } from 'src/utils/buttons/separateMenuComponents';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import randomId from 'src/utils/randomId';
import { CardContext } from "src/contexts/CardContext";
import { ErrorBoundary } from '../atoms/ErrorBoundry';

const defaultColumnSpacing = 0.2;

export enum CardType {
  noHeader,
  collapsable,
  hideable,
  visibleInitally,
  tab,
  highlight,
  tabContainer,
}
export interface ContentCardProps {
  cardId?: string;
  content?: any;
  cardHeader?: string | React.ReactNode;
  cardSubTitle?: string | React.ReactNode;
  cardTypes?: CardType[];
  components?: customComponent[];
  contentPadding?: number;
  md?: number;
  tooltipText?: string;
  children?: React.ReactNode | React.ReactElement<unknown, string | React.JSXElementConstructor<any>> | React.ReactElement<unknown, string | React.JSXElementConstructor<any>>[];
  overriddenCard?: React.ReactNode;
}

export const ContentCard = ({
  cardId = "cardID-" + randomId(),
  content,
  cardHeader: cardTitleText = cardId,
  cardSubTitle,
  cardTypes,
  tooltipText,
  contentPadding = 2,
  md = 12 + defaultColumnSpacing,
  components,
  children,
  overriddenCard
}: ContentCardProps) => {
  const { cards, toggleVisibility,showCard ,hideCard,  isEditMode } = useContext(CardContext);
  const card = cards.find(c => c.id === cardId);
  const isCollapsable = cardTypes?.some(cardType => cardType == CardType.collapsable) ?? false;

  const [collapsed, toggleExpanded] = useState<boolean>(isCollapsable ? !cardTypes?.some(cardType => cardType == CardType.visibleInitally) ?? true : false);
  const handleCollapseClick = () => toggleExpanded(!collapsed);
  const hideable = cardTypes?.some(cardType => cardType == CardType.hideable) ?? false;
  const isCardVisible = card?.isVisible ?? true;
  const isTabbed = cardTypes?.some(cardType => cardType == CardType.tab) ?? false;
  const isTabContainer = cardTypes?.some(cardType => cardType == CardType.tabContainer) ?? false;
  const isHighlighted = cardTypes?.some(cardType => cardType == CardType.highlight) ?? false;

  return (
    <Grid item md={md - defaultColumnSpacing} className={cardId}  sx={{ display: isCardVisible ? "block" : "none" }} xs={12} pt={0} pb={isTabbed ? 0 : (contentPadding == 0 ? 1 : 0)}  >
      <Collapse in={isCardVisible} timeout="auto" unmountOnExit>
        <Box pb={isTabbed ? 0 : (contentPadding == 0 ? 0 : 2)} >
          {
            overriddenCard ? <>{overriddenCard}</> : (
              <Card sx={ (isHighlighted ? {backgroundColor:"#b2c0ab"} : null)} >
                <ContentCardHeader
                  {...{ cardId, cardHeader: (cardTitleText), cardSubTitle, cardTypes, tooltipText, components }}
                  handleCollapseClick={handleCollapseClick}
                  cardId={cardId}
                  collapsable={isCollapsable}
                  hideable={hideable}
                  collapsed={collapsed}
                  />
                <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                  <Grid sx={{ width: 1 }} container pt={contentPadding}   >
                    <Box sx={{ width: 1 }} px={contentPadding} pb={contentPadding == 0 ? 0 : contentPadding + 1} >
                      <Grid container direction="row" justifyContent="space-between" alignContent={'stretch'} alignItems="stretch">
                        <ErrorBoundary>
                          {content}
                          {children}
                        </ErrorBoundary>
                      </Grid>
                    </Box>
                  </Grid>
                </Collapse>
              </Card>
            )
          }
        </Box>
      </Collapse>
    </Grid>
  );
}
interface ContentCardHeaderProps extends ContentCardProps {
  cardId: string,
  handleCollapseClick?: Function,
  hideable?: boolean,
  collapsable?: boolean,
  collapsed?: Boolean,
}

export const ContentCardHeader = ({
  cardId = "",
  cardHeader: cardTitleText = null,
  cardSubTitle = null,
  tooltipText = null,
  cardTypes = [],
  handleCollapseClick = () => { },
  hideable = false,
  collapsable = false,
  collapsed = false,
  components = null,
}: ContentCardHeaderProps) => {
  const { cards, toggleVisibility,showCard ,hideCard,  isEditMode, isCardVisible} = useContext(CardContext);
  if (!cardTypes?.some(cardType => cardType == CardType.noHeader) && !cardTypes?.some(cardType => cardType == CardType.tab)) {
    const [TopRightComponents, MenuItemsComponents] = separateMenuComponents(components);

    return (
      <>
        <Box px={2} py={2} display="flex"  justifyContent="space-between"  >
          <Box>
            <Typography gutterBottom variant="h4"> {cardTitleText} <TooltipField tooltipText={tooltipText}></TooltipField> </Typography>
            <Typography variant="subtitle2">  {cardSubTitle} </Typography>
          </Box>
          <Box display="flex" justifyContent="right" marginTop={-0.5}  >
            {TopRightComponents?.map((customComponent, i) => (
              <Grid pl={1} key={i} item>
                {customComponent.component}
              </Grid>
            ))}
            <Grid item>
              <CollapseButton collapsable={collapsable} handleCollapseClick={handleCollapseClick} collapsed={collapsed} />
              <HideButton cardId={cardId} hiddenCard={hideable} hidden={!isCardVisible(cardId)} /> 
            </Grid>
            <AdditionalActions >
              {MenuItemsComponents?.length > 0 ? MenuItemsComponents?.map((customComponent, i) => (
                <Grid key={i} item>
                  {customComponent.component}
                </Grid>
              )) : null}
            </AdditionalActions>
          </Box>
        </Box>
        <Divider />
      </>);
  }
  return (<></>);
}

const CollapseButton = ({ collapsable, handleCollapseClick, collapsed }: { collapsable: Boolean, handleCollapseClick: Function, collapsed: Boolean }) => {
  if (collapsable) {
    return (
      <IconButton color="primary" onClick={() => handleCollapseClick()} >
        <ExpandMoreTwoToneIcon sx={{ transform: collapsed ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </IconButton>
    );
  } return (<></>);
}

const HideButton = ({
  cardId,
  hiddenCard,
  hidden
}: { cardId: string, hiddenCard: Boolean, hidden: Boolean }) => {
  const { hideCard } = useContext(CardContext); // Import the context

  if (hiddenCard) {
    return (
      <IconButton color="primary" onClick={() => hideCard(cardId)} > 
        <CloseIcon sx={{ transform: hidden ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </IconButton>
    );
  } 
  return (<></>);
}

export const ContentCardsContainer = ({ contentCards, children }: { contentCards?: ContentCardProps[], children?}) => {
  return (
    <Grid
      sx={{ px: { xs: 2, md: 4 },py:{xs:0} }}
      container
      direction="row"
      justifyContent="flex-start"
      alignContent={'stretch'}
      alignItems="stretch"
      spacing={2}
      columnSpacing={0}
      pt={0}
      pb={4}
    >
      {contentCards?.map(
        (contentCard) => (<ContentCard key={contentCard.cardId} {...contentCard} />)
      )}
      {React.Children.map(
        children, child => (React.cloneElement(child))
      )}
    </Grid>
  );
}
export default ContentCardsContainer;

export const CardSectionHeader: FC<{ contentCard?: ContentCardProps, children?}> = ({ contentCard, children }) => {
  return (
    <>
      <Grid item xs={12} pb={0}  >
        <Box pb={4} >
          <Typography gutterBottom variant="h3"> {contentCard.cardHeader ?? contentCard.cardId} </Typography>
          <Typography variant="subtitle2">  {contentCard.cardSubTitle} </Typography>
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </Box>
      </Grid>
    </>
  );
}

export interface CardTab {
  value: string,
  label: string,
}
interface headerTabsProps {
  tabs: CardTab[],
  currentTab: string,
  setCurrentTab: Dispatch<SetStateAction<string>>
}



export const HeaderTabs = ({ tabs, currentTab, setCurrentTab }: headerTabsProps) => {
  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };
  return (
    <Tabs
      onChange={handleTabsChange}
      value={currentTab}
      variant="fullWidth"
      textColor="primary"

      indicatorColor="primary"
    >
      {tabs.map((tab) => (
        <Tab  key={tab.value} label={tab.label} value={tab.value} sx={{ flex: { xs: 'auto', sm: 'initial' },display: { sm: 'flex', xs: 'none' } }} />
      ))}
      <MobileTabs {...{ tabs, currentTab, setCurrentTab }} ></MobileTabs>
    </Tabs>)
}
const MobileTabs = ({ tabs, currentTab, setCurrentTab }: headerTabsProps) => {
  const actionRef = useRef<any>(null);
  const [openLocation, setOpenMenuLocation] = useState<boolean>(false);
  return (
    <>
      <Button 
        endIcon={<UnfoldMoreTwoToneIcon />}
        ref={actionRef}
        onClick={() => setOpenMenuLocation(true)}
        variant="contained"
        sx={{display: { sm: 'none', xs: 'flex' }}}
      >
        {currentTab}
      </Button>
      <Menu
        disableScrollLock
        anchorEl={actionRef.current}
        onClose={() => setOpenMenuLocation(false)}
        open={openLocation}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        {tabs.map((_tab) => (
          <MenuItem
            key={_tab.value}
            onClick={() => {
              setCurrentTab(_tab.value);
              setOpenMenuLocation(false);
            }}
          >
            {_tab.label}
          </MenuItem>
        ))}
      </Menu></>
  )
}