import {
    Checkbox,
    Grid,
    Box,
    FormControlLabel,
    Stack,
    FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TooltipField from 'src/components/atoms/TooltipField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { parseISO } from 'date-fns';
import AiButton from '../ai/AiButton';
import { CustomField } from './CustomField';



export const FormField = ({ fieldProps, formikProps, children }: { fieldProps: any, formikProps?: any, children?: React.ReactNode }) => {
    const label = fieldProps.label ?? (fieldProps.name?.charAt(0).toUpperCase() + fieldProps.name?.slice(1))
    const onChangeFunction = fieldProps?.handleChange ?? formikProps?.handleChange;
    const { t }: { t: any } = useTranslation();
    return (<>

        <Grid item display="flex" md={fieldProps.md ?? 12} xs={fieldProps.xs ?? 5.8}  >

            <Grid item md={12} xs={12} display="flex" alignItems="center" >
                {(fieldProps.date ?? false) ?
                    <>
                        <LocalizationProvider  dateAdapter={AdapterDateFns}  >
                            <DateTimePicker
                                label={label}
                                ampm={false}
                                value={
                                    formikProps?.values?.[fieldProps.name] && formikProps?.values?.[fieldProps.name] !== ""
                                    ? parseISO(formikProps?.values?.[fieldProps.name])
                                    : null
                                }
                                onChange={(value) => {
                                    formikProps.setFieldValue(fieldProps.name, value ? new Date(value).toISOString() : null);
                                }}
                                // renderInput={(props) => <TextField {...props} autoFocus fullWidth margin={"normal"} />}
                            />
                        </LocalizationProvider>
                    </>
                    :
                    (fieldProps.checkbox ?? false) ?
                        <Box display="flex" alignItems="center"  >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={fieldProps.name}
                                        checked={toBoolean(formikProps?.values?.[fieldProps.name]) ?? false}
                                        value={formikProps?.values?.[fieldProps.name] ?? false}
                                        onBlur={formikProps?.handleBlur}
                                        onChange={onChangeFunction}
                                    ></Checkbox>}
                                label={label} />
                            <Stack display="flex" spacing={2}>
                                {
                                    Boolean(formikProps?.tocuhed?.[fieldProps.name] && formikProps?.errors?.[fieldProps.name]) && (
                                        <FormHelperText error>{formikProps?.errors?.[fieldProps.name]}</FormHelperText>
                                    )
                                }
                            </Stack>
                        </Box>
                        :
                        <><CustomField
                            options={fieldProps}
                            stateProps={formikProps}
                        >{children}</CustomField>
                        </>
                }

                <TooltipField tooltipText={fieldProps.tooltipText} />
                {fieldProps.aiField ? <AiButton formikProps={formikProps} fieldName={fieldProps.name} fieldInstructions={fieldProps?.aiInstructions ?? null}></AiButton>
                    : null}
            </Grid>
        </Grid>
    </>
    );
}



const toBoolean = value => {
    if (typeof value === "string") {
        return value.toLowerCase() === "true";
    }
    return Boolean(value);
};
