import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Box, styled, useMediaQuery } from '@mui/material';


const PageTitle = styled(Box)(
  ({ theme }) => `
    
        padding: ${useMediaQuery(theme.breakpoints.down('md')) ? theme.spacing(2) : theme.spacing(4)};
        padding-bottom:  ${useMediaQuery(theme.breakpoints.down('md')) ? theme.spacing(0) : theme.spacing(0)};
        padding-top:  ${useMediaQuery(theme.breakpoints.down('md')) ? theme.spacing(1) : theme.spacing(2)};
`
);

interface PageTitleWrapperProps {
  children?: ReactNode;
}

const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return (
    <>
      <PageTitle className="MuiPageTitle-wrapper" sx={{width:"100%"}}>{children}</PageTitle>
    </>
  );
};

PageTitleWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageTitleWrapper;
