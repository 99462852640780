import { AxiosRequestConfig, Method } from "axios";
import { ProductCreateRequest } from "src/interfaces/bold/product/product";
import { SupplierProductCreateUpdateModel } from "src/interfaces/bold/product/supplier";
import { CreateOrUpdateSalesItemRequest, SalesItemExtendedSearchParams, SalesItemsByCategoryRequest, SalesItemsSearchCreteria } from "src/interfaces/bold/salesitem/salesitem";

export const BASE_URL = (process.env.REACT_APP_BOLD_API_BASE_URL?.charAt("str".length - 1) === '/') ? process.env.REACT_APP_BOLD_API_BASE_URL?.slice(0, -1) : process.env.REACT_APP_BOLD_API_BASE_URL;

export const BASE_MOCK_URL = "/api";
export const MAX_PAGESIZE = 1000;
export const createUrl = (base, path) => `${base}${path}`;
export const createUrlWithSlug = (base: string, path: string, slug: string | number): string => `${base}${path}${slug}`;

export const authorizationHeaderValue = process.env.REACT_APP_BOLD_API_AUTHENTICATION;

export const DEFAULT_LANGUAGE_ID = 0;
export const DEFAULT_PURCHASE_ORDER_PAGE = 1;
export const DEFAULT_PURCHASE_ORDER_TYPE = 1;
export const DEFAULT_SALES_ORDER_PAGE_SIZE = 20;

export const PRODUCTS_URL = BASE_URL + "/products";
export const SALES_ITEMS_URL = BASE_URL + "/sales-items";
export const IMAGES_URL = "/images";
export const SALES_ORDERS_URL = BASE_URL + "/orders";
export const RETURN_ORDERS_URL = BASE_URL + "/returns/return-orders";
export const RETURN_ORDERS_ROWS =  "/return-order-rows";
export const PRODUCT_CLASSES = "/classes";
export const PRODUCT_RECENTLY_CREATED = "/recentlycreated";
export const PRODUCT_TYPES = "/product-types";
export const SUBCLASSES = "/subclasses";
export const PRODUCT_LINES = "/lines";
export const PRODUCT_INFO = "/product-info";
export const SPECIFICATIONS = "/specifications";
export const STATUSES = "/statuses";
export const PROPERTIES = "/properties"
export const ATTRIBUTES_URL = BASE_URL + PRODUCT_TYPES + PROPERTIES;

export const PAGE_SIZE = "pagesize=";
export const SEARCH = "?search=";
export const PRODUCT_TYPE_ID = "productTypeId=";
export const MANUFACTURERS_URL = BASE_URL + "/manufacturers";
export const COUNTRIES_URL = BASE_URL + "/countries";
export const CATEGORIES_URL = BASE_URL + "/categories";
export const CATEGORIES_MODIFY_URL =  "/categories";
export const SALESASSORTMENTS =  "/sales-assortments";
export const SUPPLIER_PRODUCTS = "/supplier-products";
export const SUPPLIER_PRODUCTS_URL = BASE_URL+ SUPPLIER_PRODUCTS;
export const SUPPLIERS_URL = BASE_URL + "/suppliers";
export const PROCUREMENT_URL = BASE_URL + "/procurement";
export const PURCHASE_ORDERS_URL = PROCUREMENT_URL + "/purchase-orders";
export const PURCHASE_ORDER_DRAFTS_URL = PROCUREMENT_URL + "/purchase-order-drafts";
export const PURCHASE_ORDER_DRAFT_URL = PROCUREMENT_URL + "/purchase-order-draft";
export const BACKORDERS_URL = PROCUREMENT_URL + "/back-orders";
export const LANGUAGE_ID = "languageid=";
export const REPORTS = BASE_URL + "/reports";
export const SALES_REPORTS = REPORTS + "/sales";
export const CAMPAIGN_SALES_REPORTS = SALES_REPORTS + "/campaigns";
export const REFERENCE_DATA = "/reference-data";
export const LOW_ORDER_POINTS = BASE_URL + "/low-order-points";
export const WAREHOUSE_URL = BASE_URL + "/warehouses";
export const TRANSFER_ORDES_URL = WAREHOUSE_URL + "/transfer-orders";



export const buildRequestConfig = (
  UrlParts: (string | number | boolean)[],
  requestMethod: Method = "GET",
  data: any = null,
): AxiosRequestConfig<any> => {
  const mergedUrl = UrlParts.join('')
  const request = {
    url: mergedUrl,
    method: requestMethod,
    headers: {
      'Authorization': authorizationHeaderValue,
      
    },
    data: data
  }
  //console.log(request)
  return request
}

function logFormData(formData) {
  console.log('FormData entries:');
  for (let entry of formData.entries()) {
    console.log(entry[0] + ': ' + entry[1]);
  }
}

export const buildRequestConfig2 = (
  UrlParts: (string | number | boolean)[],
  requestMethod: Method = "GET",
  files: File[] = [],
  jsonData: any = null
): AxiosRequestConfig<any> => {
  const formData = new FormData();
  files?.forEach(file => {
    formData.append('file', file)
  });
  const jsonBlob = new Blob([
    JSON.stringify([...jsonData])],
    { type: 'application/json' });
  formData.append('jsondata', jsonBlob);
  //formData.append('jsondata', '[{"Index":1,"Model":{"ManufacturerId":4,"IsActive":true,"DisplayName":"Test1","FileName":"test.jpg","ResourceTypeId":1}}]');
  const mergedUrl = UrlParts.join('')
  const request = {
    url: mergedUrl,
    method: requestMethod,
    headers: {
      'Authorization': authorizationHeaderValue,
    },
    data: formData

  }
  logFormData(formData); // Log FormData entries
  //console.log(request)
  return request
}

//#region PRODUCT
export const getProductReferenceData = (): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL,REFERENCE_DATA,"?", LANGUAGE_ID, 2])
);


export const getProducts = (productTypeId: number, manufId: number, productLineId: number, discontinued: boolean): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, "?", PRODUCT_TYPE_ID, productTypeId, "&manufId=", manufId, "&productLineId=", productLineId, "&discontinued=", discontinued])
);
export const getAllProducts = (pageSize: number, page: number): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, SEARCH, "&", PAGE_SIZE, pageSize, "&Page=", page])
);
export const getNothing = (): AxiosRequestConfig => (
  buildRequestConfig([])
);
export const getProductById = (id: string): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, "/", id])
);
export const getProductSubClasses = (productClassId: number): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, PRODUCT_CLASSES, "/", productClassId, SUBCLASSES])
);
export const getRecentlyCreated = (): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, PRODUCT_RECENTLY_CREATED, "?", PAGE_SIZE, MAX_PAGESIZE])
);
export const getProductClasses = (): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, PRODUCT_CLASSES])
);
export const getProductTypes = (): AxiosRequestConfig => (
  buildRequestConfig([BASE_URL, PRODUCT_TYPES, "?", PAGE_SIZE, MAX_PAGESIZE])
);
export const getProductLines = (): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, PRODUCT_LINES])
);
export const getProductStatuses = (): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, STATUSES])
);
export const searchProducts = (searchKey: string): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, SEARCH, searchKey])
);
export const createProduct = (product: ProductCreateRequest): AxiosRequestConfig<any> => (
  buildRequestConfig([PRODUCTS_URL], "POST", product)
);
export const updateProduct = (id: number, product: ProductCreateRequest): AxiosRequestConfig<any> => (
  buildRequestConfig([PRODUCTS_URL, "/", id], "PATCH", product)
);
//#endregion

//#region Attributes

export const getAttribuesByProductType = (productTypeId: string, languageId: number = DEFAULT_LANGUAGE_ID): AxiosRequestConfig => (
  buildRequestConfig([BASE_URL, PRODUCT_TYPES, "/", productTypeId, PROPERTIES])
);
export const getAttributes = (productTypeId: string, sort?: string, languageId: number = DEFAULT_LANGUAGE_ID): AxiosRequestConfig => (
  buildRequestConfig([ATTRIBUTES_URL, `?${PRODUCT_TYPE_ID}=`, productTypeId])
);
export const getAttributesForProducts = (pageSize,page): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, SPECIFICATIONS, "?", PAGE_SIZE, pageSize, "&page=", page])
);
export const getUnits = (): AxiosRequestConfig => (
  buildRequestConfig([ATTRIBUTES_URL, "/units"])
);
export const getDataTypes = (): AxiosRequestConfig => (
  buildRequestConfig([ATTRIBUTES_URL, "/data-types"])
);
export const getEnumerationValues = (propertyId: number, languageId: number = DEFAULT_LANGUAGE_ID): AxiosRequestConfig => (
  buildRequestConfig([ATTRIBUTES_URL, "/", propertyId, "/enumeration",])
);
//#endregion

//#region MANUFACTURERS
export const getManufacturers = (): AxiosRequestConfig => (
  buildRequestConfig([MANUFACTURERS_URL, "?sort=Name&", PAGE_SIZE, MAX_PAGESIZE])
);

export const editManufacturers = (manufacturers, method:Method = 'POST'): AxiosRequestConfig => (
  buildRequestConfig([MANUFACTURERS_URL], method,manufacturers)
);
//#endregion

//#region Common
export const getCountries = (): AxiosRequestConfig => (
  buildRequestConfig([COUNTRIES_URL])
);


//#endregion

//#region Categories
export const getCategories = (): AxiosRequestConfig => (
  buildRequestConfig([CATEGORIES_URL, "?", LANGUAGE_ID, DEFAULT_LANGUAGE_ID, "&sort=Id&page=1&", PAGE_SIZE, MAX_PAGESIZE])
);

export const createCategory = (id, newCategoryData ): AxiosRequestConfig => (
  buildRequestConfig([CATEGORIES_URL, "/", id], "POST", newCategoryData)
);
export const createOrUpdateCategories = (categories): AxiosRequestConfig => (
  buildRequestConfig([CATEGORIES_URL, "/trees", ], "POST", categories)
);
//#endregion

//#region SUPPLIER PRODUCTS
export const getSuppliers = (): AxiosRequestConfig => (
  buildRequestConfig([SUPPLIERS_URL, "?", PAGE_SIZE, MAX_PAGESIZE])
);
export const getAllSuppliers = (pageSize,page): AxiosRequestConfig => (
  buildRequestConfig([SUPPLIERS_URL, "?", PAGE_SIZE, pageSize, "&page=", page])
);
export const getSupplierById = (id: number): AxiosRequestConfig => (
  buildRequestConfig([SUPPLIERS_URL, "/", id])
);
export const createOrUpdateSupplier = (data: any, method: Method = "POST", id: Number = null): AxiosRequestConfig => (
  buildRequestConfig([SUPPLIERS_URL, (method === "PATCH" ? `/${id}` : '')], method, data)
);
export const getSupplierProductBySku = (sku: string): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, SUPPLIER_PRODUCTS, "?sku=", sku])
);
export const getSupplierProductById = (id: number): AxiosRequestConfig => (
  buildRequestConfig([SUPPLIER_PRODUCTS_URL, "/", id])
);
export const getAllSupplierProducts = (pageSize: number, page: number): AxiosRequestConfig<SupplierProductCreateUpdateModel> => (
  buildRequestConfig([SUPPLIER_PRODUCTS_URL, SEARCH,"&sort=ProductName&", "&page=", page, "&", PAGE_SIZE, pageSize])
);
export const searchSupplierProducts = (search: string, sort: string = "ProductName", page: number = 1, pageSize: number = 50): AxiosRequestConfig<SupplierProductCreateUpdateModel> => (
  buildRequestConfig([SUPPLIER_PRODUCTS_URL, SEARCH,  "&sort=", sort, "&page=", page, "&", PAGE_SIZE, pageSize])
);
export const createOrUpdateSupplierProduct = (data: any, method: Method = "POST", id: number = null): AxiosRequestConfig => (
  buildRequestConfig([SUPPLIER_PRODUCTS_URL, (method === "PATCH" ? `/${id}` : '')], method, data)
);
export const getSupplierReferenceData = (): AxiosRequestConfig => (
  buildRequestConfig([SUPPLIERS_URL,REFERENCE_DATA])
);

//#endregion

//#region PURCHASE ORDERS
export const getPurchaseOrders = (
  search: string = "",
  sort: number = DEFAULT_PURCHASE_ORDER_PAGE,
  purchaseOrderTypeId: number = DEFAULT_PURCHASE_ORDER_TYPE,
  page: number = DEFAULT_PURCHASE_ORDER_PAGE,
  pageSize: number = 100
): AxiosRequestConfig => (
  buildRequestConfig([PURCHASE_ORDERS_URL, SEARCH, search, "&purchaseOrderTypeId=", purchaseOrderTypeId, "&sort=", sort, "&page=", page, "&", PAGE_SIZE, pageSize])
);

export const getPurchaseOrderById = (id: string): AxiosRequestConfig => (
  buildRequestConfig([PURCHASE_ORDERS_URL, "/", id])
);
export const getAllBackorders = (pageSize: number, page: number): AxiosRequestConfig => (
  buildRequestConfig([BACKORDERS_URL, "?", PAGE_SIZE, pageSize, "&Page=", page])
);

export const createOrUpdatePurchaseOrder = (data: any, method: Method = "POST", id: number = null): AxiosRequestConfig => (
  buildRequestConfig([PURCHASE_ORDERS_URL, (method === "PATCH" ? `/${id}` : '')], method, data)
);
export const createOrUpdatePurchaseOrderDraft = (data: any, method: Method = "POST", id: number = null): AxiosRequestConfig => (
  buildRequestConfig([PURCHASE_ORDER_DRAFT_URL, (method === "PATCH" ? `/${id}` : '')], method, data)
);
export const getLowOrderPoints = (): AxiosRequestConfig =>{ 
  return buildRequestConfig([LOW_ORDER_POINTS,"?nameSearchText="])
  };
  export const getLowOrderPointsById = (id): AxiosRequestConfig =>{ 
    return buildRequestConfig([LOW_ORDER_POINTS,"/",id])
    };
export const getLowOrderPointsReferenceData = (): AxiosRequestConfig => (
  buildRequestConfig([LOW_ORDER_POINTS,REFERENCE_DATA])
);
export const createOrUpdateLowOrderPoints = (data: any, method: Method = "POST", id: number = null): AxiosRequestConfig => (
  buildRequestConfig([LOW_ORDER_POINTS, (method === "PATCH" ? `/${id}` : '')], method, data)
);
export const getAllPurchaseOrders = (pageSize: number, page: number): AxiosRequestConfig<SupplierProductCreateUpdateModel> => (
  buildRequestConfig([PURCHASE_ORDERS_URL, SEARCH, "&page=", page, "&", PAGE_SIZE, pageSize])
);
export const getPurchaseOrderDraftById = (id: string): AxiosRequestConfig => (
  buildRequestConfig([BASE_URL,"/procurement/purchase-order-draft/", id])
);
export const getAllPurchaseOrderDrafts = (pageSize: number, page: number): AxiosRequestConfig<SupplierProductCreateUpdateModel> => (
  buildRequestConfig([PURCHASE_ORDER_DRAFTS_URL, SEARCH, "&page=", page, "&", PAGE_SIZE, pageSize])
);

//#endregion



//#region WAREHOUSES
export const getWarehouses = (): AxiosRequestConfig => (
  buildRequestConfig([WAREHOUSE_URL,"?searchText="])
  
);
export const getWarehouseById = (id:string): AxiosRequestConfig => (
  buildRequestConfig([WAREHOUSE_URL,"/",id])
  
);

export const getWarehouseStockById  = (pageSize:number,page:number,id:string): AxiosRequestConfig => {
  return buildRequestConfig([WAREHOUSE_URL,"/",id,"/products/quantities?",PAGE_SIZE,pageSize,"&Page=", page]);
}
export const getAllWarehouseStock  = (pageSize:number,page:number): AxiosRequestConfig => {
  return buildRequestConfig([WAREHOUSE_URL,"/products/quantities?",PAGE_SIZE,pageSize,"&Page=", page]);
}
export const getWarehouseLocationsById = (id:string): AxiosRequestConfig => (
  buildRequestConfig([WAREHOUSE_URL,"/",id,"/locations"])
  
);
export const getAllTransferOrders = (pageSize: number, page: number): AxiosRequestConfig => (
  buildRequestConfig([TRANSFER_ORDES_URL, "?", PAGE_SIZE, pageSize, "&Page=", page])
);
export const getTransferOrderById = (id:string): AxiosRequestConfig => (
  buildRequestConfig([TRANSFER_ORDES_URL,"/",id])
  
);
export const getTransferOrderReferenceData = (id:string): AxiosRequestConfig => (
  buildRequestConfig([TRANSFER_ORDES_URL,REFERENCE_DATA])
  
);


//#endregion
//#region SALES ITEMS
export const getSalesItemById = (id: number): AxiosRequestConfig => (
  buildRequestConfig([SALES_ITEMS_URL, "/", id])
);

export const getSalesItemBySku = (sku: string): AxiosRequestConfig => (
  buildRequestConfig([SALES_ITEMS_URL, "?sku=", sku])
);

export const getSalesItems = (sc: SalesItemsSearchCreteria): AxiosRequestConfig => (
  buildRequestConfig([SALES_ITEMS_URL, "?", PRODUCT_TYPE_ID, sc.ProductTypeId, "&manufId=", sc.ManufId, "&productLineId=", sc.ProductLineId, "&discontinued=", sc.Discontinued, "&sort=", sc.Sort, "&page=", sc.Page, "&" + PAGE_SIZE, sc.PageSize])
);

export const getSalesItemsByCategory = (si: SalesItemsByCategoryRequest): AxiosRequestConfig => (
  buildRequestConfig([SALES_ITEMS_URL, "?groupTreeId=", si.GroupTreeId, "&groupTreeNodeId=", si.GroupTreeNodeId, "&discontinued=", si.Discontinued, "&sort=", si.Sort, "&page=", si.Page, "&" + PAGE_SIZE, si.PageSize])
);

export const getSalesItemsBySearchParams = (sp: SalesItemExtendedSearchParams): AxiosRequestConfig => (
  buildRequestConfig([SALES_ITEMS_URL, SEARCH], "GET")
);
export const getAllSalesItems = (pageSize: number, page: number): AxiosRequestConfig => (
  buildRequestConfig([SALES_ITEMS_URL, SEARCH, "&", PAGE_SIZE, pageSize, "&Page=", page])
);
export const createOrUpdateSalesItem = (salesItem: CreateOrUpdateSalesItemRequest, method: Method = "POST", id: number = null): AxiosRequestConfig => (
  buildRequestConfig([SALES_ITEMS_URL, (id && method === "PATCH" ? `/${id}` : '')], method, salesItem)
);
export const getImagesFromSalesItemId = (salesItemId: string) => (
  buildRequestConfig([SALES_ITEMS_URL, "/", salesItemId, IMAGES_URL, "?", LANGUAGE_ID, DEFAULT_LANGUAGE_ID])
);
export const getImagesBySku = (sku: string) => (
  buildRequestConfig([PRODUCTS_URL, "/", sku, IMAGES_URL, "?", LANGUAGE_ID, DEFAULT_LANGUAGE_ID])
);

export const getProductInfoBySalesItemId = (salesItemId: string) => (
  buildRequestConfig([SALES_ITEMS_URL, "/", salesItemId, PRODUCT_INFO])
);
export const updateProductInfo = (productInfo: any) => (
  buildRequestConfig([SALES_ITEMS_URL, PRODUCT_INFO], "POST", productInfo)
);
export const getPriceListsBySky = (sku: string) => (
  buildRequestConfig([SALES_ITEMS_URL,"/",sku,SALESASSORTMENTS])
);
export const postPriceListBySku = (sku: string, newPriceLists:any) => {
  return buildRequestConfig([SALES_ITEMS_URL,"/",sku,SALESASSORTMENTS],"POST", newPriceLists)
};
export const removePriceListBySku = (sku: string, newPriceLists:any) => {
  return buildRequestConfig([SALES_ITEMS_URL,"/",sku,SALESASSORTMENTS],"DELETE", newPriceLists)
};
export const getProductInfoBySku = (sku: string, languageId: number) => (
  buildRequestConfig([SALES_ITEMS_URL, PRODUCT_INFO, "?sku=", sku, "&", LANGUAGE_ID,  languageId ?? DEFAULT_LANGUAGE_ID ])
);
export const updateSalesItemImage = (files: any, metaData: any, sku: string): AxiosRequestConfig => (
  buildRequestConfig2([PRODUCTS_URL, "/", sku, IMAGES_URL], "POST", files, metaData)
);
export const removeImagesBySku = (sku: string, resourceId: string) => (
  buildRequestConfig([PRODUCTS_URL, "/", sku, IMAGES_URL,"/", resourceId, "/unassign"], "DELETE")
);
export const updateImagesBySku = (sku: string, resourceId: string, data: any) => (
  buildRequestConfig([PRODUCTS_URL, "/", sku, IMAGES_URL,"/", resourceId, ""], "PATCH", data)
);

//#endregion
export const updateSalesItemCategoriesBySalesItemId = (salesItemId:string, categories: any) => (
  buildRequestConfig([SALES_ITEMS_URL, "/" , salesItemId, CATEGORIES_MODIFY_URL ], "POST", categories)
);
export const removeSalesItemCategoriesBySalesItemId = (salesItemId:string, categories: any) => (
  buildRequestConfig([SALES_ITEMS_URL, "/" , salesItemId, CATEGORIES_MODIFY_URL ], "DELETE", categories)
);
//#region Sales Items Attributes
export const getSalesItemAttributesBySku = (sku: string) => (
  buildRequestConfig([PRODUCTS_URL, "/", sku, SPECIFICATIONS])
);
export const updateSalesItemAttributeValues = (attributes: any,): AxiosRequestConfig => (
  buildRequestConfig([PRODUCTS_URL, SPECIFICATIONS], "POST", attributes)
);
//#endregion


//#region SALES
export const getSalesOrders = (search: string = ""): AxiosRequestConfig => (
  buildRequestConfig([SALES_ORDERS_URL, SEARCH, search, "&", PAGE_SIZE, DEFAULT_SALES_ORDER_PAGE_SIZE])
);
export const getAllSalesOrders = (pageSize: number, page: number): AxiosRequestConfig => (
  buildRequestConfig([SALES_ORDERS_URL, SEARCH, "&", PAGE_SIZE, pageSize, "&Page=", page])
);
export const getSalesOrdersById = (id: string) => (
  buildRequestConfig([SALES_ORDERS_URL, "/", id])
);
export const getOrderReferenceData = (): AxiosRequestConfig => (
  buildRequestConfig([SALES_ORDERS_URL,REFERENCE_DATA])
);
export const getAllReturnOrders = (pageSize: number, page: number): AxiosRequestConfig => (
  buildRequestConfig([RETURN_ORDERS_URL,  "?", PAGE_SIZE, pageSize, "&Page=", page])
);
export const getReturnOrderById = (id: string) => (
  buildRequestConfig([RETURN_ORDERS_URL, "/", id])
);
export const getReturnOrderReferenceData = (): AxiosRequestConfig => (
  buildRequestConfig([RETURN_ORDERS_URL,REFERENCE_DATA])
);
export const createOrUpdateReturnOrder = (data: any, method: Method = "POST", id: number = null): AxiosRequestConfig => (
  buildRequestConfig([RETURN_ORDERS_URL, (method === "PUT" ? `/${id}` : '')], method, data)
);
export const addReturnOrderRow = (data: any, method: Method = "POST", id: number = null): AxiosRequestConfig => (
  buildRequestConfig([RETURN_ORDERS_URL, (method === "POST" ? `/${id+RETURN_ORDERS_ROWS}` : '')], method, data)
);
//#endregion


//#region REPORTS
export const getCampaignReportSalesItemsLastMonth = (search: string = ""): AxiosRequestConfig => (
  buildRequestConfig([CAMPAIGN_SALES_REPORTS, '/startedlastmonth'])
);
export const getCampaignReportSalesItemsThisMonth = (search: string = ""): AxiosRequestConfig => (
  buildRequestConfig([CAMPAIGN_SALES_REPORTS, '/startedthismonth'])
);
export const getWarehouseValuesSummaryToday = (): AxiosRequestConfig => (
  buildRequestConfig([WAREHOUSE_URL, '/reports/inventory-values?selectedDate=2024-01-26'])
);

//#endregion

export const nullRequest = (): AxiosRequestConfig => (
  buildRequestConfig([''])
);