import { GridAggregationModel } from '@mui/x-data-grid-premium';
import { useState, useEffect } from 'react';

export const useAggregationModel = (tableSettings, columns): [GridAggregationModel, React.Dispatch<React.SetStateAction<GridAggregationModel>>] => {
    const [aggregationModel, setAggregationModel] = useState<GridAggregationModel>({});
    const idFieldName = tableSettings?.idPath?.join("_") ?? 'id';
    useEffect(() => {
        const newModel = { ...aggregationModel };

        columns.forEach((col) => {
            if (col.field === idFieldName) {
                newModel[col.field] = 'size';
            } else if (!newModel[col.field]) {
                if (col.type === 'number') {
                    newModel[col.field] = 'sum';
                } else {
                    // Additional default values can be set here if needed
                }
            }
        });

        setAggregationModel(newModel);
    }, [tableSettings, columns]);

    return [aggregationModel, setAggregationModel];
};
