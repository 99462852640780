import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSettingsContext } from 'src/hooks/useUserPrefs';
import { getNestedValue } from 'src/utils/settings';
import { GRID_ROOT_GROUP_ID } from '@mui/x-data-grid-premium';


export const ToggleExpandedRows = ({ props, tableSettings }) => {
    if (tableSettings.treeData == false) {
        return null;
    }
    const [settings, setSettings] = useSettingsContext();
    const expanded = getNestedValue(settings, ["TableExpanded", tableSettings?.tableName], false);
    
   
    const toggleExpandRows = () => {
        const rootGroupNode = tableSettings.apiRef?.current.getRowNode(GRID_ROOT_GROUP_ID);
        const groups = rootGroupNode ? rootGroupNode.children : [];
    
        if (groups.length > 0) {
            groups.forEach(groupId => {
                const groupNode = tableSettings.apiRef?.current.getRowNode(groupId);
                if (groupNode && groupNode.type == "group") {
                    tableSettings.apiRef.current.setRowChildrenExpansion(
                        groupId,
                        !groupNode.childrenExpanded,
                    );
                }
            });
        }
        setSettings(!expanded, "TableExpanded", tableSettings?.tableName);
    };
    const hideMenu = () => {
        tableSettings.apiRef.current.hideColumnMenu();
    }; props.hideMenu as (e) => void;
    const toggleExpandText = expanded ? "Collapse All" : "Expand All"

    return (
        <MenuItem onClick={(e) => {
            hideMenu();
            toggleExpandRows();
        }}>
            <ListItemIcon>
                {expanded ? <UnfoldLessIcon fontSize="small" /> : <UnfoldMoreIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText>{toggleExpandText}</ListItemText>

        </MenuItem>
    );
}
