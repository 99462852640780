import { set, get,keys  } from 'idb-keyval';

export const setToDB = async <T>(key: string, val: T): Promise<void> => {
  try {
    await set(key, val);
  } catch (err) {
    console.error('Error setting value to IndexedDB:', err);
  }
};

export const getFromDB = async <T>(key: string): Promise<T | null> => {
  if (!key) {
    console.error('Invalid key provided:', key);
    return null;
  }
  try {
    const cachedValue = await get<T>(key);
    return cachedValue;
  } catch (err) {
    console.error('Error getting value from IndexedDB:', err);
    return null;
  }
};


const extractApiPath = (url: string) => {
  const apiIndex = url.indexOf('/api/');
  return apiIndex !== -1 ? url.substring(apiIndex) : url;
};

export const getFromDBandFile = async <T>(key: string): Promise<T | null> => {
  if (!key) {
    console.error('Invalid key provided:', key);
    return null;
  }
  try {
    // Extract the /api/ part from the key
    const apiPathKey = extractApiPath(key);

    // Try getting value from IndexedDB by iterating over keys
    const allKeys = await keys();
    for (const cacheKey of allKeys) {
      const cacheApiPath = extractApiPath(cacheKey.toString());
      if (apiPathKey === cacheApiPath) {
        const value = await get<T>(cacheKey);
        if (value !== undefined && value !== null) {
          return value;
        }
        break;
      }
    }

    // Fallback to cache.json
    const response = await fetch('/cache.json');
    if (!response.ok) {
      throw new Error('Failed to fetch cache.json');
    }
    const cache = await response.json();

    // Search in cache.json using the extracted key
    for (const cacheKey in cache) {
      if (cache.hasOwnProperty(cacheKey)) {
        const cacheApiPath = extractApiPath(cacheKey);
        if (apiPathKey === cacheApiPath) {
          // Update IndexedDB cache if found in file
          const valueFromCacheFile = cache[cacheKey];
          await setToDB(cacheKey, valueFromCacheFile); // Use the original cache key
          return valueFromCacheFile;
        }
      }
    }

    return null;
  } catch (err) {
    console.error('Error getting value:', err);
    return null;
  }
};