import { GridRowId, GridRowModel, GridRowModes, GridRowModesModel, gridExpandedSortedRowIdsSelector, gridPaginatedVisibleSortedGridRowEntriesSelector } from "@mui/x-data-grid-premium";
import { use } from "i18next";
import React, { useMemo } from "react";
import { useState, useRef, useEffect } from "react";
import useUpdateEffect from "src/hooks/useUpdateEffect";
import { useUserEditsContext } from "src/hooks/useUserPrefs";
import { getNestedValue } from "src/utils/settings";
import tableHelper from "src/utils/tableHelper";


export const useTableEdits = (apiRef, rows, tableName, idPath: string[], rowSelectionModel) => {
  const [edits, setUserEdits] = useUserEditsContext();
  const [allRows, setAllRows] = useState(rows ?? []);
  const [rowModes, setRowModes] = useState({});
  const newRowToEditRef = useRef(null);
  const originalRows = useMemo<any>(() => rows, [rows]);
  const changedRows = getNestedValue(edits, ["TableChangedRows", tableName], []);
  const [hasUnsavedRows, setHasUnsavedRows] = useState(changedRows?.length > 0);
  const [changeCounter, setChangeCounter] = useState(0);
  const changedRowsRef = useRef<any[]>(changedRows);
  const subscriptionRef = useRef(false);
  const [pinnedRows, setPinnedRows] = React.useState<{ top: GridRowModel[]; bottom: GridRowModel[] }>({ top: [], bottom: [], });
  const addAndPinMultiEditRow = () => { executeAddAndPinMultiEditRow(pinnedRows, setPinnedRows, idPath); };
  const removeMultiEditRow = () => { executeRemoveMultiEditRow(pinnedRows, setPinnedRows, idPath); };
  const updateChangedRowsSetting = () => { setUserEdits(changedRowsRef.current, "TableChangedRows", tableName ?? "UnNamed"); };
  const addRow = (newRow) => { executeAddRow(newRow, idPath, setHasUnsavedRows, updateChangedRowsSetting, newRowToEditRef, changedRowsRef, setChangeCounter); };
  const deleteRow = (rowId) => { executeDeleteRow(rowId, originalRows, idPath, setHasUnsavedRows, updateChangedRowsSetting, changedRowsRef, setChangeCounter); };
  const discardRowChanges = (id) => { executeDiscardRowChanges(id, idPath, setHasUnsavedRows, updateChangedRowsSetting, changedRowsRef, setChangeCounter); };
  const discardAllChanges = () => { executeDiscardChanges(setUserEdits, tableName, setHasUnsavedRows, changedRowsRef, setChangeCounter); };
  const handleCellEditEnd = (params, event, details) => executeHandleCellEditEnd(params, apiRef, idPath, allRows, setAllRows, rowSelectionModel, setUserEdits, setHasUnsavedRows, updateChangedRowsSetting, setChangeCounter, changedRowsRef);

  useEffect(() => { mergeRowsAndUpdate(originalRows, changedRows, idPath, allRows, setAllRows, apiRef); }, [originalRows, changedRows, changeCounter]);
  useEffect(() => { setEditRowMode(newRowToEditRef, setRowModes); }, [allRows]);
  useEffect(() => { subscribeToRowEditStop(apiRef, handleCellEditEnd, subscriptionRef); }, [apiRef]);

  return { allRows, changedRowsRef, hasUnsavedRows, discardAllChanges, discardRowChanges, addRow, rowModes, setRowModes, addAndPinMultiEditRow, removeMultiEditRow, pinnedRows, deleteRow };
};

const mergeRowsAndUpdate = (originalRows, changedRows, idPath, allRows, setAllRows, apiRef) => {
  const originalRowsMap = mapRowsById(originalRows, idPath);
  const changedRowsMap = mapRowsById(changedRows, idPath);
  const newRows = [];
  changedRowsMap.forEach((row, rowId) => {
    if (!originalRowsMap.has(rowId)) {
      newRows.push(row);
    } else {
      originalRowsMap.set(rowId, row);
    }
  });
  const updatedRows = Array.from(originalRowsMap.values());
  const combinedRows = [...newRows, ...updatedRows];

  if (combinedRows.length > 0) {
    if (combinedRows.length !== allRows.length || combinedRows.some((row, index) => row !== allRows[index])) {
      if (allRows.length === 0) {
        setAllRows(combinedRows);
      }
      else {
        apiRef.current.updateRows(combinedRows);
      }
    }
  }
};

const updateSelectedRowsBasedOnEdit = (params, allRows, setAllRows, rowSelectionModel, idPath, apiRef, setUserEdits, setHasUnsavedRows, updateChangedRowsSetting, setChangeCounter, changedRowsRef) => {
  // Find the selected rows
  const selectedRowIds = rowSelectionModel; // Assuming this is an array of selected row IDs
  const selectedRows = allRows.filter(row => selectedRowIds.includes(tableHelper.getRowIdFromPath(row, idPath)));

  // Updated rows with the edits applied
  let updatedRows = [...allRows];

  selectedRows.forEach(selectedRow => {
    const originalRow = { ...selectedRow };
    const editedRow = JSON.parse(JSON.stringify(originalRow)); // Deep copy to modify

    // Assuming editParams.values holds the edited values in a flat structure
    Object.entries(params.values).forEach(([key, value]) => {
      const [baseKey, nestedKey] = key.split('_');
      if (baseKey && nestedKey && editedRow[baseKey]) {
        // For nested structures
        editedRow[baseKey][nestedKey] = value;
      } else {
        // For flat structures
        editedRow[key] = value;
      }
    });

    // Update the row in the overall rows array
    updatedRows = updatedRows.map(row =>
      tableHelper.getRowIdFromPath(row, idPath) === tableHelper.getRowIdFromPath(editedRow, idPath) ? editedRow : row
    );

    // Update the list of changed rows
    const rowIdToUpdate = tableHelper.getRowIdFromPath(editedRow, idPath);
    const existingRowIndex = changedRowsRef.current.findIndex(row => tableHelper.getRowIdFromPath(row, idPath) === rowIdToUpdate);
    if (existingRowIndex !== -1) {
      // Update existing row entry in changedRowsRef

      changedRowsRef.current[existingRowIndex] = editedRow;
    } else {
      // Add new edited row to changedRowsRef
      changedRowsRef.current.push(editedRow);
    }
    setHasUnsavedRows(true);
    updateChangedRowsSetting(); // Assuming this function knows how to handle the updated changedRowsRef
    setChangeCounter(prev => prev + 1);
  });

  // Finally, update the allRows state to reflect the changes
  //setAllRows(updatedRows);
  apiRef.current.updateRows(updatedRows);
};

const generateUniqueNegativeId = () => {
  const timestamp = new Date().getTime();
  const randomFactor = Math.floor(Math.random() * 1000);
  return -(timestamp + randomFactor);
};

const executeAddAndPinMultiEditRow = (pinnedRows, setPinnedRows, idPath) => {
  const uniqueId = 'multi-edit-row'; // A static ID for the multi-edit row
  let multiEditRow = {};
  tableHelper.updateRowIdWithPath(multiEditRow, uniqueId, idPath);
  if (!pinnedRows.top.some(row => tableHelper.getRowIdFromPath(row, idPath) === uniqueId)) {
    setPinnedRows(prev => ({
      ...prev,
      top: [multiEditRow, ...prev.top],
    }));
  }
};
const executeRemoveMultiEditRow = (pinnedRows, setPinnedRows, idPath) => {
  const uniqueId = 'multi-edit-row';
  setPinnedRows(prev => ({
    ...prev,
    top: prev.top.filter(row => tableHelper.getRowIdFromPath(row, idPath) !== uniqueId),
    bottom: prev.bottom.filter(row => tableHelper.getRowIdFromPath(row, idPath) !== uniqueId),
  }));
};

const executeAddRow = (newRow, idPath, setHasUnsavedRows, updateChangedRows, newRowToEditRef, changedRowsRef, setChangeCounter) => {
  const uniqueNegativeId = generateUniqueNegativeId();
  tableHelper.updateRowIdWithPath(newRow, uniqueNegativeId, idPath);
  newRowToEditRef.current = uniqueNegativeId;
  changedRowsRef.current = [...changedRowsRef.current, newRow];
  setHasUnsavedRows(true);
  updateChangedRows();
  setChangeCounter(prev => prev + 1);
};
const executeDeleteRow = (rowId, originalRows, idPath, setHasUnsavedRows, updateChangedRows, changedRowsRef, setChangeCounter) => {
  // Mark the specified row as deleted
  const markRowAsDeleted = (row) => ({ ...row, deleted: true });

  // Find and mark the specified row as deleted
  const originalRowIndex = originalRows.findIndex(row => tableHelper.getRowIdFromPath(row, idPath) === rowId);
  let deletedRow;
  const existingIndex = changedRowsRef.current.findIndex(row => tableHelper.getRowIdFromPath(row, idPath) === rowId);
  if (existingIndex !== -1) {
    deletedRow = markRowAsDeleted(changedRowsRef.current[existingIndex]);
    changedRowsRef.current[existingIndex] = deletedRow;
  } else if (originalRowIndex !== -1) {
    const originalRow = originalRows[originalRowIndex];
    deletedRow = markRowAsDeleted(originalRow);
    changedRowsRef.current.push(deletedRow);
  } else {
    console.error('Row not found in originalRows or changedRows', rowId);
    return;
  }

  // Mark any children of the deleted row as deleted
  const allRows = [...originalRows, ...changedRowsRef.current];
  const childRows = allRows.filter(row => row.path?.includes(rowId));
  childRows.forEach(childRow => {
    const childRowIndex = changedRowsRef.current.findIndex(changedRow => tableHelper.getRowIdFromPath(changedRow, idPath) === tableHelper.getRowIdFromPath(childRow, idPath));
    if (childRowIndex !== -1) {
      changedRowsRef.current[childRowIndex] = markRowAsDeleted(changedRowsRef.current[childRowIndex]);
    } else {
      // If the child row is not already in changedRowsRef, add it as deleted
      changedRowsRef.current.push(markRowAsDeleted(childRow));
    }
  });

  setHasUnsavedRows(true);
  updateChangedRows();
  setChangeCounter(prev => prev + 1);
};




const executeDiscardRowChanges = (id, idPath, setHasUnsavedRows, updateChangedRows, changedRowsRef, setChangeCounter) => {
  const idRegex = new RegExp(`${id}$`);
  changedRowsRef.current = changedRowsRef.current.filter(row => !row.path?.some(pathSegment => idRegex.test(pathSegment)) && tableHelper.getRowIdFromPath(row, idPath) !== id);
  setHasUnsavedRows(changedRowsRef.current.length > 0);
  updateChangedRows();
  setChangeCounter(prev => prev + 1);
};

const executeDiscardChanges = (setUserEdits, tableName, setHasUnsavedRows, changedRowsRef, setChangeCounter) => {
  setUserEdits([], "TableChangedRows", tableName ?? "UnNamed");
  setHasUnsavedRows(false);
  setChangeCounter(prev => prev + 1);
  changedRowsRef.current = [];
};

const handleSingleCellEditEnd = (params, originalRow, idPath, setHasUnsavedRows, updateChangedRowsSetting, setChangeCounter, changedRowsRef) => {
  const editedRow = JSON.parse(JSON.stringify(originalRow));
  const [baseKey, nestedKey] = params.field.split('_');
  if (baseKey && nestedKey && editedRow[baseKey]) {
    editedRow[baseKey][nestedKey] = editedRow[params.field];
  }
  const rowIndex = changedRowsRef.current.findIndex(row => tableHelper.getRowIdFromPath(row, idPath) === params.id);
  if (rowIndex !== -1) {
    changedRowsRef.current[rowIndex] = editedRow;
  } else {
    changedRowsRef.current.push(editedRow);
  }
  setHasUnsavedRows(true);
  updateChangedRowsSetting();
  setChangeCounter(prev => prev + 1);
};


const setEditRowMode = (newRowToEditRef, setRowModes) => {
  if (newRowToEditRef.current != null) {
    setRowModes(prevModes => ({
      ...prevModes,
      [newRowToEditRef.current]: { mode: 'Edit', fieldToFocus: 'Manufacturer_Value' }
    }));
    newRowToEditRef.current = null; // Reset the ref after setting the row mode
  }
};

const executeHandleCellEditEnd = (
  params,
  apiRef,
  idPath,
  allRows,
  setAllRows,
  rowSelectionModel,
  setUserEdits,
  setHasUnsavedRows,
  updateChangedRowsSetting,
  setChangeCounter,
  changedRowsRef
) => {
  const isMultiEditRow = params.id === 'multi-edit-row';
  if (isMultiEditRow) {
    updateSelectedRowsBasedOnEdit(params, allRows, setAllRows, rowSelectionModel, idPath, apiRef, setUserEdits, setHasUnsavedRows, updateChangedRowsSetting, setChangeCounter, changedRowsRef);
    return;
  }
  const originalRow = apiRef?.current.getRow(params.id);
  if (originalRow) {
    handleSingleCellEditEnd(params, originalRow, idPath, setHasUnsavedRows, updateChangedRowsSetting, setChangeCounter, changedRowsRef);
  }
};
const subscribeToRowEditStop = (apiRef, handleCellEditEnd, subscriptionRef) => {
  if (apiRef && apiRef.current && typeof apiRef.current.subscribeEvent === 'function' && !subscriptionRef.current) {
    apiRef.current.subscribeEvent('rowEditStop', handleCellEditEnd);
    subscriptionRef.current = true;
  }
};
const mapRowsById = (rows, idPath) => {
  return new Map(rows?.map(row => {
    const rowId = tableHelper.getRowIdFromPath(row, idPath);
    return [rowId, row];
  }));
};
