import { Box, Divider, Grid, IconButton, Menu } from '@mui/material';
import  { ActionButtonProps } from 'src/components/atoms/buttons/CustomButton';
import { FC, useRef, useState } from 'react';
import CustomButton from './CustomButton';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';

const defaultVariant = 'contained';

const LineContianer: FC<{ actionButton: ActionButtonProps }> = ({ actionButton }) => {
    return (
    <Grid key={actionButton.title} item>
        <CustomButton {...actionButton}></CustomButton>
        <Divider />
    </Grid>
    );
  }


export const AdditionalActions: FC<{ actionButtons?: ActionButtonProps[], children?}> = ({ actionButtons, children }) => {

    const actionRef = useRef<any>(null);
    const [isOpen, setOpenMenuLocation] = useState<boolean>(false);
    let  hasContent = (actionButtons || children) ?? false;
     
    if(hasContent){
        return (
            <Box display="flex"  >
    
                <IconButton
                    sx={{
                        ml: 1
                    }}
                    color="primary"
                    ref={actionRef}
                    className={"additional-actions"}
                    onClick={() => setOpenMenuLocation(true)}
                >
                    <MoreVertTwoToneIcon />
                </IconButton>
                
                <Menu
                    disableScrollLock
                    anchorEl={actionRef.current}
                    className='additional-actions-menu'
                    onClose={() => setOpenMenuLocation(false)}
                    open={isOpen}
                     anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right'
                    }}
                >
                    {actionButtons?.map((actionButton, index) => (
                        <Grid  key={index} item>
                            <CustomButton {...actionButton}></CustomButton>
                        </Grid>
                    ))}
                    {children?.map((child, index) => (
                        <Grid key={index} item>
                            {child}                       
                        </Grid>
    
                    ))}
                </Menu>
            </Box>
        );
    }else{
        return <></>
    }
    

}



export default AdditionalActions;
