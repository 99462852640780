import { Stack, Paper } from "@mui/material";

export const DetailPanelContainer = ({ children }) => {
    return (<Stack sx={{ py: 0, height: '100%', boxSizing: 'border-box', }} direction="column">
        <Paper sx={{ flex: 1, mx: 'auto', width: '100%', p: 0, backgroundColor: "#f5f3ee" }}>
            <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                {children}
            </Stack>
        </Paper>
    </Stack>);
}

export default DetailPanelContainer;