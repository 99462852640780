import { createContext, FC } from 'react';
import { useUserEdits } from 'src/hooks/useUserPrefs';

export interface IEditsContext {
  userEdits?: any,
  setUserEdits?: (value: any, key?: string, nestedKey?: string) => void
}

export const EditsContext = createContext<IEditsContext>({});

export const EditsProvider: FC = ({ children }) => {
  const [userEdits, setUserEdits] = useUserEdits();
  return (
    <EditsContext.Provider
      value={{
        userEdits,
        setUserEdits,
        
      }}
    >
      {children}
    </EditsContext.Provider>
  );
};
