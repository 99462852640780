export const ID_FIELD_PRODUCT_TYPES = ["Id"];
export const ID_FIELD_PRODUCT_TYPES_DROPDOWN = ["ProductType"];
export const ID_ATTRIBUTES = ["Id"];
export const ID_FIELD_CATEGORIES_ON_SALES_ITEMS = ["GroupTreeNode","Key"];
export const ID_FIELD_CATEGORIES = ["CategoryId"];
export const ID_FIELD_COUNTRIES = ["Id"];
export const ID_FIELD_MANUFACTURERS = ["Key"];
export const ID_FIELD_PRODUCTS = ["ProductId"];
export const ID_FIELD_SUPPLIERS = ["Supplier","Key"];
export const ID_FIELD_SALLES_ITEMS = ["SalesItemId"];
export const ID_FIELD_PRODUCT_CLASSES = ["Key"];
export const ID_FIELD_PRODUCT_LINES = ["ProductLine"];
export const ID_FIELD_DATA_TYPES = ["Key"];
export const ID_FIELD_UNITS = ["Id"];
export const ID_FIELD_SUPPLIER_PRODUCTS = ["SupplierAssortmentItemId"];
export const ID_FIELD_SALES_ORDERS = ["SalesOrderId"];
export const ID_FIELD_PURCHASE_ORDERS = ["PurchaseOrderId"];
export const ID_FIELD_CAMPAIGN_REPORT_SALES_ITEMS = ["Id"];