import useAuth from "src/hooks/useAuth";
import { User, UserRole } from "src/interfaces/userPreferences/user";

export const isSuperAdmin = (role: UserRole) => {
    if (!role || role == null)
        return false;

    var result = role === "superadmin";
    return result;
}

export const isAdmin = (role?: UserRole) => {
    if (!role || role == null)
        return false;

    return ["admin", "superadmin"].includes(role);
}

export const getAuthenticatedUser = (): User => {
    var { user } = useAuth();
    return user;
}