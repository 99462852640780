
//RETURNS
export const mapReturnToValues = (values) => {
  let updatedObject: any = {
    Description: values.Description,
    ReturnReasonId: values.ReturnReasonId,
  }
  return updatedObject;
}
export const mapProductToReturnRow = (values) => {
  const product = values?.newProduct;
  return product
}
//SUPPLIER PRODUCTS
export const mapProductFormValuesToSupplierProductUpdate = (values) => {
  let supplierProduct: any = {
    Price: values.Price,
    ManufacturerId: values.Manufacturer,
    VendorProductName: values.SupplierProduct_Value,
    VendorProductNo: values.SupplierProduct,
    ProductsId: values.Product,
    Status: values.Status,
    Quantity: values.QuantityAvailableAtSupplier,
    ValidFrom: values.ValidFrom ?? new Date(),
    EAN: values.EAN,

  }
  return supplierProduct;
}
export const mapProductFormValuesToSupplierProductCreate = (values) => {
  let supplierProduct: any = {
    "VendorAssortmentsId": values.SupplierAssortment,
    ManufacturerId: values.Manufacturer,
    ManufacturerProductNo: values.ManufacturerProduct,
    VendorProductName: values.SupplierProduct_Value,
    VendorProductNo: values.SupplierProduct,
    Price: values.Price,
    TARIC: parseInt(values.TARIC ?? null),
    ProductsId: values.Product,
    Status: values.Status,
    Quantity: values.QuantityAvailableAtSupplier,
    ETA: values.ETA ?? null,
    ValidFrom: values.ValidFrom ?? new Date(),
    ValidTo: values.ValidTo,
    EAN: values.EAN,
  }
  return supplierProduct;
}


//SUPPLIERS
export const mapFormValuesToSupplier = (values) => {
  return {
    ShortName: "SHORT NAME PLACEHOLDER",
    Name: values.Name,
    Description: values.Description,
    PaymentMethodId: values.PaymentMethodId,
    PaymentTermId: values.PaymentTermId,
    ShippingTermId: values.ShippingTermId,
    ShippingMethodId: values.ShippingMethodId,
    SendTypeId: values.SendTypeId,
    CurrencyId: values.CurrencyId,
    Address: {
      Company: values.Address_Company,
      Department: values.Address_Department,
      Title: values.Address_Title,
      Salutation: values.Address_Salutation,
      FirstName: values.Address_FirstName,
      MiddleNameInitial: values.Address_MiddleNameInitial,
      LastName: values.Address_LastName,
      Street: values.Address_Street,
      StreetAdditionalInformation: values.Address_StreetAdditionalInformation,
      Postcode: values.Address_Postcode,
      City: values.Address_City,
      County: values.Address_County,
      State: values.Address_State,
      Country: {
        Key: values.Address_Country,
      },
      AdditionalInformation: values.Address_AdditionalInformation,
      DoorCode: values.Address_DoorCode,
      Contact: values.Contact,
      EmailAddress: values.Address_Email,
      PhoneNumber: values.Address_Phone,
      MobileNumber: values.Address_Mobile,
      FaxNumber: values.Address_Fax,
      WebAddress: values.Address_Web
    },
    Email: values.Email,
    Web: values.Address_Web,
    OurCustomerNumber: values.OurCustomerNumber,
    StandardLeadTime: values.StandardLeadTime,
    Disabled: values.Disabled,
    Contact: values.Contact,
    Phone: values.Address_Phone,
    Mobile: values.Address_Mobile,
    Fax: values.Address_Fax,
    CorporateRegistrationNumber: values.CorporateRegistrationNumber,
    ShippingTermsDestination: values.ShippingTermsDestination,
    SendDestination: values.SendDestination,
    DropShipEnabled: values.DropShipEnabled
  };
};



//PURCHASE ORDER DRAFTS
export const mapFormValuesToPurchaseOrderDraft = (values) => {
  return {
    PurchaseOrderDraftId: values.PurchaseOrderDraftId,
    PurchaseOrderDraftType: values.PurchaseOrderDraftType,
    Status: values.Status ? { key: parseInt(values.Status), value: "" } : undefined,
    OrderCurrency: values.OrderCurrency_Value ? { key: parseInt(values.OrderCurrency_Value), value: "" } : undefined,
    OrderValueInDefaultCurrency: parseFloat(values.OrderValueInBaseCurrency),
    OrderValueIncMiscCharges: parseFloat(values.OrderValueIncMiscCharges),
    Supplier: values.Supplier,
    SupplierContact: values.SupplierContact,
    ModifyDate: values.ModifyDate,
    ModifiedBy: values.ModifiedBy,
    CreateDate: values.CreateDate,
    CreatedBy: values.CreatedBy,
    SupplierAddress: {
      Company: values.SupplierAddress_Company,
      Department: values.SupplierAddress_Department,
      Title: values.SupplierAddress_Title,
      Salutation: values.SupplierAddress_Salutation,
      FirstName: values.SupplierAddress_FirstName,
      MiddleNameInitial: values.SupplierAddress_MiddleNameInitial,
      LastName: values.SupplierAddress_LastName,
      Street: values.SupplierAddress_Street,
      StreetAdditionalInformation: values.SupplierAddress_StreetAdditionalInformation,
      Postcode: values.SupplierAddress_Postcode,
      City: values.SupplierAddress_City,
      County: values.SupplierAddress_County,
      State: values.SupplierAddress_State,
      Country: { Key: values.SupplierAddress_Country },
      AdditionalInformation: values.SupplierAddress_AdditionalInformation,
      DoorCode: values.SupplierAddress_DoorCode,
      Contact: values.Contact,
      EmailAddress: values.SupplierAddress_Email,
      PhoneNumber: values.SupplierAddress_Phone,
      MobileNumber: values.SupplierAddress_Mobile,
      FaxNumber: values.SupplierAddress_Fax,
      WebAddress: values.SupplierAddress_Web
    },
    SendDestination: values.SendDestination,
    OurContact: values.OurContact,
    DefaultCurrency: values.Currencies_Value ? { key: parseInt(values.Currencies_Value), value: "" } : undefined,
    ShippingMethod: values.ShippingMethod ? { key: parseInt(values.ShippingMethod), value: "" } : undefined,
    ShippingTermsDestination: values.ShippingTermsDestination,
    ShippingTerm: values.ShippingTerm ? { key: parseInt(values.ShippingTerm), value: "" } : undefined,
    PaymentTerm: values.PaymentTerm ? { key: parseInt(values.PaymentTerm), value: "" } : undefined,
    PaymentMethod: values.PaymentMethod ? { key: parseInt(values.PaymentMethod), value: "" } : undefined
  };
};

export const mapFormValuesToPurchaseOrderDraftPatch = (values) => {
  return {
    Status: values.Status ? parseInt(values.Status) : undefined,
    NoAutoAssign: values.NoAutoAssign,
    PaymentMethodId: values.PaymentMethod ? parseInt(values.PaymentMethod) : undefined,
    PaymentTermId: values.PaymentTerm ? parseInt(values.PaymentTerm) : undefined,
    ShippingMethodId: values.ShippingMethod ? parseInt(values.ShippingMethod) : undefined,
    ShippingTermId: values.ShippingTerm ? parseInt(values.ShippingTerm) : undefined,
    ShippingTermsDestination: values.ShippingTermsDestination,
    SendDestination: values.SendDestination,
    SendType: values.SendType ? parseInt(values.SendType) : 1,
    Comment: values.Comment
  };
};


export const mapTableDataToManufacturers = (rows: any[], isDelete = false) => {
  return rows
    .filter(row => isDelete ? row.deleted : !row.deleted) // Filter rows based on the isDelete flag and the deleted property
    .map((row) => ({
      Id: row.Manufacturer.Key < 0 ? null : row.Manufacturer.Key,
      Name: row.Manufacturer.Value
    }));
};



//CATEGORIES
export const mapTableDataToCategories = (values) => {
  const treeMap = new Map(); // To track trees and their new negative IDs
  const categoryMap = new Map(); // To track categories and their new negative IDs
  let treeCounter = -1;
  let categoryCounter = -1;

  const output = [];

  values.forEach((item) => {
    let treeId, categoryId;

    // Handle Tree ID assignment
    if (item.ParentId === null) { // New tree case
      if (treeMap.has(item.CategoryTreeId)) {
        treeId = treeMap.get(item.CategoryTreeId);
      } else {
        treeId = 0//treeCounter--;
        treeMap.set(item.CategoryTreeId, treeId);
      }
    } else {
      treeId = item.CategoryTreeId > 0 ? item.CategoryTreeId : treeMap.get(item.CategoryTreeId);
    }

    // Handle Category ID assignment
    if (categoryMap.has(item.CategoryId)) {
      categoryId = categoryMap.get(item.CategoryId);
    } else {
      categoryId = categoryCounter--;
      categoryMap.set(item.CategoryId, categoryId);
    }

    // Find or create the tree in the output
    let tree = output.find(t => t.Id === treeId);
    if (!tree) {
      tree = {
        Id: treeId,
        TreeType: 1, // Assuming TreeType is static as not provided in input
        Name: item.Name, // Assuming the name of the first category as tree name
        ItemsAllowedInMultipleGroups: true, // Assuming static as well
        Categories: []
      };
      output.push(tree);
    }

    // Add category to the tree
    tree.Categories.push({
      CategoryTreeId: treeId,
      CategoryId: categoryId,
      ParentId: item.ParentId === null ? null : (item.ParentId > 0 ? item.ParentId : categoryMap.get(item.ParentId)),
      Name: item.Name,
      SortOrder: tree.Categories.length + 1 // Assuming SortOrder is sequential
    });
  });

  return output;
};