import {
  Avatar,
  Tooltip,
  IconButton,
  Box,
  Button,
  styled,
  InputBase,
  useTheme
} from '@mui/material';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';

const MessageInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

const Input = styled('input')({
  display: 'none'
});

function BottomBarContent({sendMessage}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [message, setMessage] = useState(''); 
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSendMessage = useCallback(() => {
    sendMessage(message)
    setMessage('');
  }, [message]);

  return (
    <Box
      sx={{
        background: theme.colors.alpha.white[50],
        display: 'flex',
        alignItems: 'center',
        p: 2,
      }}
    >
      <Box flexGrow={1} display="flex" alignItems="center">
        <Avatar
          sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}
        />
        <MessageInputWrapper
          autoFocus
          placeholder={t('Write your message here...')}
          fullWidth
          value={message} // Controlled component
          onChange={handleMessageChange} // Update state on change
        />
      </Box>
      <Box>
        <Input accept="image/*" id="messenger-upload-file" type="file" />
        <Tooltip arrow placement="top" title={t('Attach a file')}>
          <label htmlFor="messenger-upload-file">
            <IconButton sx={{ mx: 1 }} color="primary" component="span">
              <AttachFileTwoToneIcon fontSize="small" />
            </IconButton>
          </label>
        </Tooltip>
        <Button
          startIcon={<SendTwoToneIcon />}
          variant="contained"
          onClick={handleSendMessage} // Send message on click
        >
          {t('Send')}
        </Button>
      </Box>
    </Box>
  );
}

export default BottomBarContent;
