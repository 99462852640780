// CardContext.js
import { createContext, useState, useEffect } from 'react';

interface CardContextProps {
  cards: { id: number | string; isVisible: boolean; width: number }[];
  toggleVisibility: (cardId: number | string) => void;
  showCard: (cardId: number | string) => void;
  hideCard: (cardId: number | string) => void;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  isCardVisible: (cardId: number | string) => boolean;
}


export const CardContext = createContext<CardContextProps>({
  cards: [],
  toggleVisibility: () => {},
  showCard: () => {},
  hideCard: () => {},
  isEditMode: false,
  setIsEditMode: () => {},
  isCardVisible: ()=>true
});

export const CardProvider = ({ children }) => {
  const initialCards = JSON.parse(localStorage.getItem('cards')) || [];
  const [cards, setCards] = useState(initialCards);
  const [isEditMode, setIsEditMode] = useState(false);
  useEffect(() => {
    localStorage.setItem('cards', JSON.stringify(cards));
  }, [cards]);

  const toggleVisibility = (cardId: number | string) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId ? { ...card, isVisible: !card.isVisible } : card
      )
    );
  };
  const isCardVisible = (cardId: number | string) => {
    const card = cards.find(c => c.id === cardId);
    return card?.isVisible ?? false;
  };
  const showCard = (cardId: number | string) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId ? { ...card, isVisible: true } : card
      )
    );
  };

  const hideCard = (cardId: number | string) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId ? { ...card, isVisible: false } : card
      )
    );
  };

  return (
    <CardContext.Provider value={{ cards, toggleVisibility, showCard, hideCard, isEditMode, setIsEditMode,isCardVisible }}>
      {children}
    </CardContext.Provider>
  );
};