import { useState, useContext, Dispatch, SetStateAction } from "react";
import { MessageContext } from "src/contexts/MessageContext";
import useRefMounted from "../useRefMounted";

export const useApiStates = <R, >():
[
  results: R,
  setResults: Dispatch<SetStateAction<R>>,
  setMessageContext: Function,
  isMountedRef: () => boolean,

] => {
  const [results, setResults] = useState<R>(null);
  const isMountedRef = useRefMounted();
  const { updateMessageStatus } = useContext(MessageContext);
  return [results, setResults, updateMessageStatus, isMountedRef];
}
