import { FC, useState, createContext } from 'react';

type SidebarContext = {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  desktopSidebarToggle: boolean; // New state for desktop sidebar
  toggleDesktopSidebar: () => void; // Function to toggle desktop sidebar
  closeDesktopSidebar: () => void; // Function to close desktop sidebar
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [desktopSidebarToggle, setDesktopSidebarToggle] = useState(true); // Default to true for desktop

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  // New function to toggle desktop sidebar
  const toggleDesktopSidebar = () => {
    setDesktopSidebarToggle(!desktopSidebarToggle);
  };

  // New function to close desktop sidebar
  const closeDesktopSidebar = () => {
    setDesktopSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        desktopSidebarToggle, // Provide state and functions for desktop sidebar
        toggleDesktopSidebar,
        closeDesktopSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
