import { useState } from 'react';
import OpenAI from "openai";

export const useAi = (): [any, Function] => {
  const [responses, setResponses] = useState([]);

  const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_SECRET_KEY ?? "", dangerouslyAllowBrowser: true });

  const fetchCompletionStream = async (newInstructions) => {
    console.log(newInstructions)
    try {
      const stream = await openai.chat.completions.create({
        messages: [{ role: "system", content: newInstructions }],
        model: "gpt-3.5-turbo",
        stream: true
      });
      for await (const part of stream) {
        console.log(part)
        setResponses(prevResponses => [...prevResponses, part.choices[0].delta.content]);
      }
      console.log(stream)
    } catch (error) {
      console.error(error);
      setResponses(prevResponses => [...prevResponses, "Error fetching completion."]);
    }
  };


  const handleResponseStream = async (newInstructions) => {
    const message = await fetchCompletionStream(newInstructions);
    setResponses(oldResponses => [...oldResponses, message]);
  };


  const handleSubmit = (newInstructions) => {

    handleResponseStream(newInstructions);
  };
  return [responses, handleSubmit]
};


export const useAiChat = (): [any, Function] => {
  const [responses, setResponses] = useState([]);
  const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_SECRET_KEY, dangerouslyAllowBrowser: true });
  const fetchCompletion = async (newInstructions) => {
    try {
      console.log(newInstructions)
      const response = await openai.chat.completions.create({
        messages: [{ role: "system", content: newInstructions }],
        model: "gpt-3.5-turbo",
      });
      console.log(response)
      return response.choices[0].message.content
    } catch (error) {
      console.error(error);
      setResponses(prevResponses => [...prevResponses, "Error fetching completion."]);
    }
  };

  const handleResponse = async (newInstructions) => {
    const message = await fetchCompletion(newInstructions);
    setResponses(oldResponses => [...oldResponses, message]);
  };


  const handleSubmit = (newInstructions) => {
    handleResponse(newInstructions);
  };
  return [responses, handleSubmit]
};
