import { Button, Dialog, DialogContent, TextField, MenuItem } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import { useState } from "react";
import CustomButton from "src/components/atoms/buttons/CustomButton";
import { ContentCard } from "src/components/organisms/ContentCard";
import { useAi } from "src/hooks/useAi";
import RestoreIcon from '@mui/icons-material/Restore';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { MultiEditPopup } from "./MultiLineEdit";



export function CustomToolbar(tableSettings) {
    return (
        <GridToolbarContainer>
            {/* <GridToolbarQuickFilter></GridToolbarQuickFilter> */}
            {/* <GridToolbarColumnsButton /> */}
            {/* <GridToolbarFilterButton /> */}
            {/* <GridToolbarDensitySelector /> */}
            {/* {tableSettings?.checkboxSelection ?
                <MultiEditButton tableSettings={tableSettings}></MultiEditButton>
                : null} */}

            {/* <Button
                disabled={!tableSettings?.hasUnsavedRows || tableSettings?.isSaving}
                onClick={() => {
                    tableSettings?.discardChanges()
                }}
                startIcon={<RestoreIcon />}
            >
                Discard changes
            </Button> */}
            {/* <GridToolbarExport /> */}
        </GridToolbarContainer>
    );
}

const MultiEditButton = ({ tableSettings }) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = () => { setOpen(false); };
    return (
        <>
            <Button
                disabled={!tableSettings?.rowSelectionModel?.length}
                onClick={handleClickOpen}
                startIcon={<AppRegistrationIcon />}
            >
                Edit Multiple Rows
            </Button>
            <MultiEditPopup

                open={open}
                onClose={handleClose}
                tableSettings={tableSettings}
            />
        </>
    );
};

