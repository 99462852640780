import { GridColumnMenu } from "@mui/x-data-grid-premium";
import { ToggleExpandedRows } from "./ToggleExpandTree";
import { ToggleHeaderFilter } from "./ToggleHeaderFilter";

export function CustomColumnMenu({ props, tableSettings }) {
    // console.log("CustomColumnMenu", props, tableSettings)
    return (
        <GridColumnMenu
            {...props}
            slots={{
                // Add new item
                columnMenuUserItem: (props) => (
                    <>
                        <ToggleExpandedRows props={props} tableSettings={tableSettings} />
                        <ToggleHeaderFilter props={props} tableSettings={tableSettings} />
                    </>)
            }}
            slotProps={{
                columnMenuUserItem: {
                    displayOrder: 15,
                },
            }}
        />
    );
}
