import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { User } from 'src/interfaces/userPreferences/user';
import { authApi, getTenant } from 'src/utils/axios';
import PropTypes from 'prop-types';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends AuthState {
  verify: () => Promise<User>,
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (tenant: string, email: string, name: string, password: string, role: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthServerResponse {
  accessToken: string;
  user: User;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction): AuthState => {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    };
  }
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  verify: () => Promise.resolve<User>(null),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});


export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        if (getTenant()) {
          const user = await verify();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: (user ? true : false),
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const verify = async (): Promise<User> => {
    const response = await authApi().get('/verify');
    const { user } = response.data;
    return user;
  };

  const login = async (email: string, password: string): Promise<void> => {
    const response = await authApi().post<AuthServerResponse>('/login',
      {
        email: email,
        password: password
      }
    );

    const { user } = response.data;

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = async (): Promise<void> => {
    await authApi().post<AuthServerResponse>('/logout');
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (
    tenant: string,
    email: string,
    name: string,
    password: string,
    role: string
  ): Promise<void> => {

    const response = await authApi(tenant).post<AuthServerResponse>(
      '/register',
      {
        email,
        name,
        password,
        role
      }
    );
    const { user } = response.data;

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        verify,
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
