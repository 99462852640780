import PageHeader from 'src/components/organisms/PageHeader';
import Footer from 'src/components/molecules/footer/Footer';
import { CustomDataGrid } from 'src/components/organisms/CustomDataGrid';
import ContentCardsContainer, { CardType, ContentCard } from 'src/components/organisms/ContentCard';
import { useBackordersRowsTable, useBackordersTable } from 'src/hooks/table/configs/useBackordersTable';
import DetailPanelContainer from 'src/components/organisms/tables/DetailPanelContainer';
import { generateTablePageButtons } from 'src/utils/generateTablePageButtons';

export const Backorders = () => {
    const [columns, tableSettings] = useBackordersTable();
    return (
        <>
            <PageHeader  {...{
                title: "Backorders",
                subTitle: "Backorders",
                components: [
                    ...generateTablePageButtons(tableSettings),
                ]
            }} />
            <ContentCardsContainer >
                <ContentCard {...{ cardTypes: [CardType.noHeader], contentPadding: 0 }}>
                    <CustomDataGrid columns={columns} tableSettings={tableSettings}  getDetailPanelContent={(row) => <DetailPanelContent {...{ row }} />} />
                </ContentCard>
            </ContentCardsContainer>
            <Footer />
        </>
    );
}
export default Backorders;




const DetailPanelContent = ({ row }) => {
    const [columns, tableSettings] = useBackordersRowsTable(row?.row?.DetailRows);
    return (
        <DetailPanelContainer>
            <CustomDataGrid columns={columns} tableSettings={tableSettings} ></CustomDataGrid>
        </DetailPanelContainer>
    );
}
