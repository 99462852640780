import { AxiosRequestConfig } from "axios";
import { useCallback, useRef, useState } from "react";
import { callApi } from "src/utils/callApi";
import useUpdateEffect from "../useUpdateEffect";
import { useApiStates } from "./useApiStates";
import useIsFirstRender from "../useIsFirstRender";

const useRequest = <D = any, R = any>(): [
    results: R,
    setRequest:  (request: AxiosRequestConfig<D>) => Promise<any> | void,
    abort?: () => void
] => {
    const [results, setResults, updateMessageStatus, isMountedRef] = useApiStates<R>();
    const [request, setPostPutRequest] = useState<AxiosRequestConfig<D>>(null);
    const isFirstRender = useIsFirstRender();
    const [successCallback, setSuccessCallback] = useState<(<T, >(responseData: T) => T | any) | undefined>(undefined)
    const abortController = useRef(null);

    const abort = useCallback(() => {
        return abortController?.current && abortController.current.abort('Cancelled by user input. useUpdateDashboardContext');
    }, [request]);

    const setRequest = (newRequest: AxiosRequestConfig<D>) => {
        setPostPutRequest(newRequest)
        if (newRequest?.url === undefined) return Promise.resolve();
        
        // return new Promise((resolve, reject) => {
        //     callApi(newRequest, isMountedRef, setResults, setMessageContext, successCallback, abortController?.current?.signal, resolve, reject);
        // });
    }
    useUpdateEffect(() => {
        abortController.current = new AbortController();
        callApi(request, isMountedRef, setResults, updateMessageStatus, successCallback, abortController.current.signal);
    }, [request])
    return [results, setRequest, abort];
};

export default useRequest;