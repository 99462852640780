import {  GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { tableSettingsProps, useTableSettings } from '../tableSettings/useTableSettings';
import tableHelper from 'src/utils/tableHelper';
import { useGetWarehouseLocationsById, useGetWarehouseStockById, useGetWarehouses } from '../../services/bold/warehouse';
import useUpdateEffect from 'src/hooks/useUpdateEffect';


export const useWarehousesTable = (): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetWarehouses();
  const idPath = ["WarehouseId"];
  const columns = React.useMemo(
    () => [
      { field: 'actions', getActions: (params) => tableHelper.buttonColumn(params, "Edit", "/warehouse/warehouses/edit?id=", "WarehouseId", "Edit Warehouse"), type: 'actions', headerName: 'Edit', minWidth: 80, maxWidth: 120, flex: 0.8, },
      { field: 'WarehouseId', headerName: 'WarehouseId', },
      { field: 'WarehouseName', headerName: 'WarehouseName', flex:2 },
      { field: 'WarehouseType', headerName: 'WarehouseType',flex:1 },
      { field: 'City', headerName: 'City', flex:1},
      { field: 'Country', headerName: 'Country', flex:1},
      { field: 'IsExternal', headerName: 'IsExternal',flex:1 ,minWidth:50, type:"boolean" },
      { field: 'IsActive', headerName: 'IsActive',flex:1 ,minWidth:50, type:"boolean" },
    ],
    [],
  );
  const tableName = "WarehousesTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  return [ columns, tableSettings];
};




export const usePackingStationsRowsTable = (initialValues): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const [rows, setRows] = useState([])
  useUpdateEffect(() => {
    if (rows?.length < 1) {
      setRows(initialValues?.PackingStations ?? [])
    }
  }, [initialValues?.PackingStations])
  const columns = React.useMemo(
    () => [
      { field: 'Id', headerName: 'Id', type:"number"},
      { field: 'DeliveryNotePrinterId', headerName: 'DeliveryNotePrinterId'},
      { field: 'Name', headerName: 'Name', flex:1},
      { field: 'ComputerName', headerName: 'ComputerName', flex:1},
      { field: 'WaybillPrinterId', headerName: 'WaybillPrinterId', type:"number"},


    ],
    [initialValues],
  );
  const tableName = "PackingStationsRowsTable";
  const tableSettings = useTableSettings({ columns, rows, tableName });
  tableSettings.autoHeight = true;
  tableSettings.hasToolbar=false;
  
  return [ columns, tableSettings];
};

export const useReceivingStationsRowsTable = (initialValues): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const [rows, setRows] = useState([])
  useUpdateEffect(() => {
    if (rows?.length < 1) {
      setRows(initialValues?.ReceivingStations ?? [])
    }
  }, [initialValues?.ReceivingStations])
  const columns = React.useMemo(
    () => [
      { field: 'Id', headerName: 'Id', type:"number"},
      
      { field: 'Name', headerName: 'Name', flex:1},
      { field: 'ComputerName', headerName: 'ComputerName', flex:1},
      { field: 'LabelPrinterName', headerName: 'LabelPrinterName', flex:1},
      { field: 'LabelPrinterId', headerName: 'LabelPrinterId', type:"number"},
      { field: 'ReceivingLocationName', headerName: 'ReceivingLocationName', flex:1},
      { field: 'ReceivingLocationId', headerName: 'ReceivingLocationId', type:"number"},


    ],
    [initialValues],
  );
  const tableName = "ReceivingStationsRowsTable";
  const tableSettings = useTableSettings({ columns, rows, tableName });
  tableSettings.autoHeight = true;
  tableSettings.hasToolbar=false;
  
  return [ columns, tableSettings];
};


export const useWarehouseLocationsTable = (editId): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetWarehouseLocationsById(editId);
  const columns = React.useMemo(
    () => [
      { field: 'Id', headerName: 'Id',  type:"number" },
      { field: 'Label', headerName: 'Label', flex:1 },
      { field: 'WarehouseId', headerName: 'WarehouseId',  type:"number" },
      { field: 'PartitionId', headerName: 'PartitionId',  type:"number" },
      
      
      
    ],
    [],
  );
  const tableName = "WarehouseLocationsTable";
  const tableSettings = useTableSettings({ columns, rows, tableName });
  tableSettings.hasToolbar=false;
  return [ columns, tableSettings];
};
export const useWarehouseStocksTable = (editId): [columns: GridColDef[], tableSettings: tableSettingsProps] => {
  const rows = useGetWarehouseStockById(editId);
  const idPath=["ArticleNumber"];
  const columns = React.useMemo(
    () => [
      { field: 'SalesItemsId', headerName: 'SalesItemsId',  type:"number" },
      { field: 'ArticleNumber', headerName: 'ArticleNumber',  type:"number" },
      { field: 'WarehouseQty', headerName: 'WarehouseQty',  type:"number", flex:1 },
      { field: 'AvailableQty', headerName: 'AvailableQty',  type:"number" },
      { field: 'OrderFulfillmentReservedQty', headerName: 'OrderFulfillmentReservedQty',  type:"number" },
      { field: 'TransferOrderReservedQty', headerName: 'TransferOrderReservedQty',  type:"number" },
      
      
      
    ],
    [],
  );
  const tableName = "WarehouseStockTable";
  const tableSettings = useTableSettings({ columns,idPath, rows, tableName });
  
  tableSettings.hasToolbar=false;
  return [ columns, tableSettings];
};
