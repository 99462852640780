import React from "react";

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  };

  static getDerivedStateFromError(error) {
    console.log(error)
    return { hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  };

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  };
}