import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axios";

export const callApi = (request: AxiosRequestConfig<any>, isMountedRef, setResults, updateMessageStatus, callback?: <T = any, >(responseData: T) => T | any, abortSignal?: AbortSignal, resolve?, reject?) => {
  const abortController = new AbortController();

  if (request != null) { request.signal = abortSignal ?? abortController?.signal }
  //console.log(request.url)
  axiosInstance(request)

    .then((response) => {
      if (!isMountedRef()) {
        return;
      }

      if (response.data) {
        setResults(response.data);
        if (resolve) resolve(response.data);

      } else {
        // console.log(response.statusText);
        updateMessageStatus(response.statusText, "warning")
      }
      if (callback) callback(response.data);

    })
    .catch((err) => {
      const errorMessage = err.response?.data ?? err.message;
      // console.log(errorMessage);
      updateMessageStatus(errorMessage, "error")
      if (reject) reject(err);
    });

  return () => {
    abortController.abort();
  }
}



export const shouldRequest = (
  requestNothingOnNull: boolean,
  requestFunctionValues: any[]
): boolean => {
  // console.log(requestNothingOnNull,requestFunctionValues)
  if (requestNothingOnNull) {
    const firstValue = requestFunctionValues[0];
    //   console.log(firstValue)
    if (
      !requestFunctionValues.length ||
      firstValue === "" ||
      firstValue === null ||
      firstValue === undefined ||
      Number.isNaN(firstValue)
    ) {
      //console.log("Returning false")
      return false;
    }
  }
  return true;
};
