import { Box, Avatar, Typography, Card, styled, Divider } from '@mui/material';
import {
  formatDistance,
  format,
  subDays
} from 'date-fns';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import Scrollbar from 'src/components/atoms/scrollbar/Scrollbar';

const  ChatContent = ({messages}) => {
  return (
    <Box p={3} pb={0} height={"90vh"}>
      <Scrollbar>
      <DividerWrapper>
        {format(subDays(new Date(), 3), 'MMMM dd yyyy')}
      </DividerWrapper>
      {messages.map((message, index) => (
        <MessageComponent key={index} message={message} />
      ))}
      </Scrollbar>
    </Box>
  );
}

export default ChatContent;


const MessageComponent = ({ message }) => {
  const isUser = message.user
  const Wrapper = isUser ? CardWrapperPrimary : CardWrapperSecondary;
  const justifyContent = isUser ? 'flex-end' : 'flex-start';
  const avatar = isUser ? "U" : "/static/images/avatars/avatar-ai.png";
  const alt = isUser ? "U" : "AI";

  // Function to render the timestamp if it exists
  const renderTimestamp = timestamp => {
    if (!timestamp) return null; // Don't render anything if there's no timestamp
    return (
      <Typography variant="subtitle1" sx={{ pt: 1, display: 'flex', alignItems: 'center' }}>
        <ScheduleTwoToneIcon sx={{ mr: 0.5 }} fontSize="small" />
        {formatDistance(timestamp, new Date(), { addSuffix: true })}
      </Typography>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent={justifyContent}
      py={3}
    >
      {!isUser && <Avatar variant="rounded" sx={{ width: 50, height: 50 }} alt={alt} src={avatar} />}
      <Box
        display="flex"
        alignItems={isUser ? 'flex-end' : 'flex-start'}
        flexDirection="column"
        justifyContent={isUser ? 'flex-end' : 'flex-start'}
        m={2}
      >
        <Wrapper>{message.content}</Wrapper>
        {renderTimestamp(message.timestamp)} {/* Conditional rendering */}
      </Box>
      {isUser && <Avatar variant="rounded" sx={{ width: 50, height: 50 }} alt={alt} src={avatar} />}
    </Box>
  );
};

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

const CardWrapperSecondary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);