import { TextField } from "@mui/material";
import { get } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface CustomFieldProps {
    stateProps?: {
        values?: any,
        handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
        touched?: any,
        errors?: any,
        handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        state?: any,
        setState?: React.Dispatch<React.SetStateAction<any>>
    },
    options: {
        label?: string,
        disabled?: boolean,
        multiLine?: boolean,
        select?: boolean,
        isMulti?: boolean,
        type?: string,
        name: string,
        height?: string,
        topMargin?: string,
        selectOpen?: boolean,
        className?: string,
        setSelectOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    },
    children?: ReactNode,
}

export const CustomField = ({
    options,
    stateProps,
    children,
}: CustomFieldProps) => {
    const { t } = useTranslation();
     // Fallback for options if not provided

    const label = t(options.label ?? (options.name.charAt(0).toUpperCase() + options.name.slice(1)));

    const onChangeFunction = stateProps?.setState != undefined ? (event) => { stateProps?.setState(event.target.value) } : stateProps?.handleChange;
    let value = stateProps?.state ?? get(stateProps?.values ?? {}, options.name, "");
    if ((options.select && !options.isMulti) && (typeof value === 'object' || value === undefined)) {
        value = ''; // Resetting value to empty string for invalid value
    }
    const [selectOpen, setSelectOpen] = useState(false);
    useEffect(() => {
        if (options.selectOpen !== undefined) {
            setSelectOpen(options?.selectOpen);
        }
    }, [options.selectOpen]);
    const handleSelectOpen = () => {
        setSelectOpen(true);
        if (typeof options?.setSelectOpen === 'function') {
            options?.setSelectOpen(true);
        }
    };
    const handleSelectClose = () => {
        setSelectOpen(false);
        if (typeof options?.setSelectOpen === 'function') {
            options?.setSelectOpen(false);
        }
    };

    const errorText = get(stateProps?.touched, options.name) && get(stateProps?.errors, options.name);

    return (
        <TextField
            label={label}
            name={options.name}
            value={value}
            className={options.className ?? "custom-field-"+label?.toLowerCase().replace(" ", "-")}
            onBlur={stateProps?.handleBlur}
            multiline={options.multiLine ?? false}
            onChange={onChangeFunction}
            inputProps={{ readOnly: options.disabled }}
            select={options.select ?? false}
            SelectProps={{
                multiple: options.isMulti ?? false, open: selectOpen,
                onOpen: handleSelectOpen,
                onClose: handleSelectClose,
            }}
            helperText={errorText}
            error={Boolean(errorText)}
            type={options.type ?? "text"}
            variant="outlined"
            fullWidth
            sx={{
                "&.MuiFormControl-root": {
                    marginTop: options?.topMargin ?? "16px",

                },
                '& .MuiOutlinedInput-root': {
                    height: options?.height == null ? "auto" : options.height + "  !important",
                },
                '& label.MuiInputLabel-root': {
                    marginTop: options?.height == null ? "0" : "-5px  !important",
                }
            }}
            margin="normal"
        >
            {children}
        </TextField>
    );
};