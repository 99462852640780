import React from 'react';
import { ToastOptions, toast } from 'react-toastify';

export const useAlert = () => {


  const showAlert = (message, status, config: any = {}) => {
    const { details, autoClose = true } = config;
    if (!message || !status) return;

    const toastContent = ({ closeToast }) => (
      <div>
        {message}
        {details && (
          <div>
            <button onClick={() => alert(details)}>More Info</button>
          </div>
        )}
      </div>
    );

    const options: ToastOptions = {
      autoClose: autoClose ? 5000 : false,
      closeButton: true,
      closeOnClick: true,
    };

    switch (status) {
      case 'success':
        toast.success(toastContent, options);
        break;
      case 'error':
        toast.error(toastContent, options);
        break;
      default:
        console.warn('Unsupported status:', status);
    }
  };

  return showAlert;
};
